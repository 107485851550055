import { APIRequestStatus } from 'core/constants/redux';
import { VisualizationTypes } from 'core/constants/visualizations';
import { SortingOrderTypes } from 'core/models/report-builder/report-builder';
import { IReportState, ReportActionTypes } from './report-store.state';

export const reportInitialState: IReportState = {
  requestProcessing: {
    [ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.SAVE_REPORT_CONFIG_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportActionTypes.GET_RAW_CONFIG_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.FILTER_LOAD_REQUEST]: {},
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.EXPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.EXPORT_LIST_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.DYNAMIC_FILTERS_LOAD_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportActionTypes.ACCESS_CONTROL_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.DELETE_REPORT]: APIRequestStatus.Processing,
    [ReportActionTypes.REORDER_COLUMNS]: APIRequestStatus.Processing,
    [ReportActionTypes.AUTODRILLDOWN_V2]: APIRequestStatus.Processing,
    [ReportActionTypes.AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportActionTypes.SAVE_COLUMNS_ORDER_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.RETRY_EXPORT_REQUEST]: APIRequestStatus.Processing,
    [ReportActionTypes.DATA_REFRESH_STATUS_LOAD_REQUEST]: APIRequestStatus.Success,
    [ReportActionTypes.GET_EXPRESSION_FIELDS]: APIRequestStatus.Success,
  },
  reportId: null,
  reportConfig: null,
  filterConfig: [],
  appliedFilters: {},
  sort: [],
  isMultiSort: false,
  page: {
    PageIndex: 1,
    PageSize: 10,
  },
  reportData: null,
  prevAppliedFilters: [],
  activeFilters: {},
  useChangedFilters: true,
  drilldownPoint: null,
  selectedToolbarMenuItem: -1,
  columns: null, // active columns
  appliedDimensions: undefined,
  activeDimensions: undefined,
  allMeasures: undefined,
  dynamicDimensions: null,
  isDynamicMeasuresLoaded: null,
  activeDimensionsLength: 0,
  zeroValuesEnabled: false,
  autodrilldownData: {
    selectedMeasure: null,
    selectedRowData: null,
    page: {
      PageIndex: 1,
      PageSize: 25,
    },
    columns: null,
    reportData: null,
    sort: null,
    isMultiSort: false,
    drilldownFields: null,
    measureFieldAlias: null,
    rowPinnedClicked: false,
    clickedOnColumnTotal: false,
    clickedOnRowTotal: false,
  },
  autoDrilldownStateID: '',
  autoDrilldownDataV2: null,
  pivotBinningType: '',
  exportData: null,
  exportListData: [],
  triggerExport: '',
  isDailyLimitReached: false,
  totalDailyExports: 0,
  exportsConsumed: 0,
  jobObject: {
    headerName: '',
    jobsArray: [],
  },
  isResetClicked: false,
  isUsersModified: false,
  isFirstLoad: true,
  dynamicFilters: null,
  reportName: '',
  reportDescription: '',
  isFormDisabled: true,
  Visualization: {
    type: VisualizationTypes.Table,
    attributes: {
      showDataLabels: false,
      stacked: false,
      fullStacked: false,
      showPercentage: false,
    },
  },
  selectedRowData: {},
  mappedMetaFields: null,
  showOthersInfoPopUp: false,
  showReport: false,
  pivotSort: SortingOrderTypes.ASC,
  savedReportConfig: null,
  enablesSaveAs: false,
  trackUserDrilldown: false,
  accessStatusCode: null,
  fields: [],
};

export default reportInitialState;
