/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';

import { isWrongInput } from 'core/utils/report.util';
import {
  IDashboardConfig, IFilterItem, IFilterLinkInfo, IFilterListResponse, IFilterStore, IFiltersOnchange, IPanel,
  IPanelPayloads, IReportMappedColumns, IReportMappedFilters, IReportWidget, IRequestPanelDataSuccessPayload, IWidgetSettings,
} from 'core/models/dashboard';
import { LoadingStatus, PanelSourceTypes } from 'core/constants/dashboard';
import { IConfigMetaData, IReportConfig, ReportType } from 'core/models/report-builder/report-builder';
import {
  filterAppliedOnDashboardBuilder, filterAppliedOnPanels, getDefaultDimensions, getFiltersPayload, getGlobalFilter, getPanelHeight, getPanelWidth, isPanelsFiltered, updatePartitionFilterValue,
} from 'core/utils/dashboard.util';
import {
  ColumnDataTypes, DimensionMode, DimensionStatus, MAX_DESC_CHARACTER_LIMIT, MAX_NAME_CHARACTER_LIMIT, MIN_CHARACTER_LIMIT,
  statusCodes,
} from 'core/constants/report';
import { VisualizationTypes } from 'core/constants/visualizations';
import { IEditProfileItem } from 'core/models/profile';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Obj } from 'core/models/obj';
import {
  isValidArray, isValidArrayAndNotEmpty, isValidObjectAndNotEmpty,
} from 'components/feature/Report/ReportSidebar/common/helpers';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import { FilterType, UserFilterPayloadType } from 'core/constants/filter';
import { IFilterResponse } from 'core/models/filter';
import { IColumn, IFilterConfig } from 'core/models/report-response';
import { ObjModel } from 'core/models';
import {
  defaultGroupFilter, defaultGroupFilterKey, defaultGroupMetaDataKey, getDefaultPreAppliedFilter, defaultPreAppliedKey,
} from 'core/constants/dashboard-builder';
import { getFilterConfigFromTemplate, getMappedMetaFields, removeObjectWithSameId } from 'core/utils/common.utility';
import { IEditDBProfileItem } from 'redux-v2/global-store/global-store.state';
import {
  ActionInfoObject, IEditTagPayload, IEditTagsData, IReportData, IReportSection, ITagsData,
} from 'redux-v2/report-listing/report-listing.state';
import { groupReportBasedOnTags } from 'redux-v2/report-listing/report-listing.util';
import { IChartAttributesKeys } from 'components/feature/Report/ReportSidebar/ChartsVisualization/chart-visualization.type';
import { FeatureContext as FeatureContextType } from 'core/constants/common';
import { userStatusEnum } from 'components/feature/Report/ReportSidebar/common/constants';
import { IDashboardState } from './dashboard-store.state';

export const getReportSections = (
  payload: Array<IReportData>,
  globalTags: ITagsData[],
) => {
  let newRes: IReportSection[] = [];
  if (payload) {
    newRes = groupReportBasedOnTags(payload, globalTags, true);
  }
  return [...newRes];
};

// Validate dashboard builder save button based on the title, description and the number of panels
// @Title - Title of the dashboard builder (Form Value)
// @Description - Description of the dashboard builder (Form Value)
// @panels - Panels info of the dashboard builder (Form Value)
export const getIsDashboardBuilderSaveFormDisable = (
  Title: string,
  Description: string,
  panels: Array<IPanel>,
) => (isWrongInput(Title)
  || isWrongInput(Description)
  || Title.length > MAX_NAME_CHARACTER_LIMIT
  || Description.length > MAX_DESC_CHARACTER_LIMIT
  || Title.trim().length < MIN_CHARACTER_LIMIT
  || Description.trim().length < MIN_CHARACTER_LIMIT
  || !Title.trim().length
  || !Description.trim().length
  || panels.length === 0);

// Generate the panel array on selection of add widget in the dashboardbuilder for widget from report
// @reportConfig - Report configuration of the selected widget
// @numOfPanel - Number of panels added in the dashboard builder
// @widgetSettings - Widget settings form value once after the report selected
export const getAddWidgetInfo = (reportConfig: IReportConfig, numOfPanel : number, widgetSettings:IWidgetSettings): IPanel => {
  const {
    Details: { Description },
    ReportConfigId,
  } = reportConfig;
  const {
    PageSize, Title, Type, attributes, uniquePanelId, Columns, SelectedMeasures,
  } = widgetSettings;

  let columnsForDashboard = [...Columns];
  if (Type === VisualizationTypes.KPI) {
    columnsForDashboard = SelectedMeasures;
  }
  const width = getPanelWidth(6, Type);
  const height = getPanelHeight(4, Type);

  return {
    Id: uniquePanelId,
    TitleSection: { Title, Description },
    Source: {
      GlobalFiltersMapping: null,
      PageSize,
      SourceType: PanelSourceTypes.Report,
      SourceId: ReportConfigId,
      ReportId: ReportConfigId,
    },
    Target: {
      Type,
      GridLayout: {
        w: width,
        h: height,
        x: numOfPanel % 2 !== 0 ? 6 : 0,
        y: Infinity,
        i: (numOfPanel + 1)?.toString(),
        isResizable: true,
        isDraggable: true,
        ...getDefaultDimensions(Type),
      },
      Visualization: {
        Builder: {
          Columns: columnsForDashboard,
          Properties: attributes,
        },
        Type,
      },
    },
    FeatureContext: reportConfig?.FeatureContext,
  };
};

// Generate the panel array on selection of add widget in the dashboardbuilder for Embed URL
// @state - Dashboard state (Dashboard builder can be retrieved)
export const generateEmbedUrlConfig = (state: IDashboardState): IPanel => {
  const { title, decodedUrl } = state.dashboardBuilder.embedURLSettings;
  const sourceId = uuidv4();
  const width = getPanelWidth(6, VisualizationTypes.EmbeddedUrl);
  const height = getPanelHeight(4, VisualizationTypes.EmbeddedUrl);
  const numOfPanel = state.dashboardConfig.Panels.length;
  return {
    Id: uuidv4(),
    TitleSection: {
      Title: title,
      Description: '',
    },
    Source: {
      SourceType: PanelSourceTypes.EmbeddedUrl,
      SourceId: sourceId,
      ReportId: sourceId,
      GlobalFiltersMapping: {},
      PageSize: 0,
      Url: decodedUrl,
    },
    Target: {
      GridLayout: {
        w: width,
        h: height,
        x: numOfPanel % 2 !== 0 ? 6 : 0,
        y: Infinity,
        i: (numOfPanel + 1).toString(),
        isResizable: true,
        isDraggable: true,
        ...getDefaultDimensions(VisualizationTypes.EmbeddedUrl),
      },
      Type: VisualizationTypes.EmbeddedUrl,
      Visualization: {
        Builder: null,
        Type: VisualizationTypes.EmbeddedUrl,
        DisplayName: '',
      },
    },
    FeatureContext: state.dashboardBuilder?.dashboardBuilderInfo?.reportConfig?.FeatureContext,
  };
};

// This Methods get the stored tags object and convert it into the expected payload of tag-unTag API
// updatedTagsList: List with the updated tags
// activeTagsList: Original list of tags
// tagItemInfo: Indivual dashboard or report ActionInfoObject
// dashboardId: dashboard ID of which tags are being updated
export const getEditTagsPayload = (updatedTagsList:Array<IEditTagsData>, activeTagsList: Array<IEditTagsData>, tagItemInfo:ActionInfoObject): IEditTagPayload => {
  const payload: IEditTagPayload = {
    ...tagItemInfo,
    ObjectType: tagItemInfo.ObjectType,
    ObjectId: tagItemInfo.ObjectId,
    Id: tagItemInfo.ObjectId,
    SourceType: null,
    Type: tagItemInfo.Type,
    Tags: [],
    Untags: [],
  };
  const tagIds: Array<string> = [];
  const untagIds: Array<string> = [];

  if (isValidArrayAndNotEmpty(updatedTagsList)) {
    updatedTagsList.forEach((item:IEditTagsData) => {
      if (item.checked && activeTagsList.findIndex((tagItem) => tagItem.Id === item.Id && tagItem.checked === item.checked) < 0) {
        tagIds.push(item.Id);
      } else if (!item.checked && activeTagsList.findIndex((tagItem) => tagItem.Id === item.Id && tagItem.checked === item.checked) < 0) {
        untagIds.push(item.Id);
      }
    });
  }

  return { ...payload, Tags: tagIds, Untags: untagIds };
};

export const getAddProfilesToDashboardPayload = (updatedProfilesList: Array<IEditProfileItem>, dashboardId:string) => {
  const profilesList :Array<IEditDBProfileItem> = [];
  const removedProfilesList :Array<string> = [];
  updatedProfilesList.forEach((item:IEditProfileItem) => {
    if (item.checked) {
      profilesList.push({ Id: item.Id, IsPinnedInProfile: item.IsPinnedInProfile, IsHomeInProfile: item.IsHomeInProfile });
    }
  });

  const payload = {
    Id: dashboardId,
    Type: ReportType.Custom,
    AddedProfiles: profilesList,
    RemovedProfiles: removedProfilesList,
  };
  return payload;
};

// Update the selected filter object with any change of name filter value or panel linking
// @filterStore: dashboard builder filterStore
// @payload: updated filter change info as key value pair
export const getUpdatedFilterJson = (filterStore : IFilterStore, payload:IFilterItem) => {
  const updatedFilterObj = {
    ...filterStore.selectedFilter,
    ...payload,
  };

  return ({
    ...filterStore,
    selectedFilter: updatedFilterObj,
  });
};

// On Add filter operation it update the dynamic filter store with newely add filter info
// @filterStore: dashboard filterStore
// @filterItem: newely added filter info
export const getUpdatedFilterJsonOnAdd = (filterStoreObj : IFilterStore, payload:IFilterItem, partitionColumnFilterDateLimit?: number): IFilterStore => {
  const filterStore = cloneDeep(filterStoreObj);
  const selectedFilterId = payload.ID;
  const panelMappedPartitionColumns = filterStore.panelMappedPartitionColumns;
  let updatedFilterObj: IFilterItem = {
    ...filterStore?.selectedFilter,
    ...payload,
  };
  const linkedPanelFilter: IFilterLinkInfo = updatedFilterObj?.linkedPanelFilter;

  let isPartitionFilter = false;
  if (isValidObjectAndNotEmpty(linkedPanelFilter)) {
    Object.keys(linkedPanelFilter).forEach((panelId) => {
      const reportFilterIds = panelMappedPartitionColumns[panelId]?.partitionFilterIds;
      if (panelMappedPartitionColumns[panelId]?.isPartitioned && isValidArray(reportFilterIds) && reportFilterIds?.find((filterId) => filterId === linkedPanelFilter[panelId])) isPartitionFilter = true;
    });
  }

  updatedFilterObj = {
    ...updatedFilterObj,
    IsPartitionColumn: isPartitionFilter,
  };
  const filterLoadData = updatedFilterObj.filterLoadData;

  if (filterLoadData) { // editing
    updatedFilterObj = {
      ...updatedFilterObj,
      filterData: new FilterFetchUtility(null).GetDefaultSingleFilter(updatedFilterObj, null),
    };
  }
  if (updatedFilterObj?.Type === FilterType.UserMultiSelect) {
    updatedFilterObj = {
      ...updatedFilterObj,
      filterData: {
        ...updatedFilterObj.filterData,
        FilterResponse: {
          Type: UserFilterPayloadType.All,
          UserIds: [],
        },
      },
    };

    if (updatedFilterObj?.Metadata?.UserDropdownSelectType) {
      updatedFilterObj = {
        ...updatedFilterObj,
        Metadata: {
          ...updatedFilterObj.Metadata,
          UserDropdownSelectType: UserFilterPayloadType.All,
        },
      };
    }
  }
  if (isPartitionFilter && updatedFilterObj?.Type === FilterType.DateRange) {
    updatedFilterObj = updatePartitionFilterValue(updatedFilterObj, partitionColumnFilterDateLimit);
  }

  const updatedFilterList = cloneDeep(filterStore.dynamicGlobalFilters);

  const index = updatedFilterList?.findIndex((item) => item.ID === payload.ID);
  if (index > -1) updatedFilterList[index] = updatedFilterObj;
  else {
    if (updatedFilterObj.Type === FilterType.UserMultiSelect) {
      updatedFilterObj.filterLinkedKey = defaultGroupFilterKey;
      if (!updatedFilterList.includes(defaultGroupFilter)) { updatedFilterList.push(defaultGroupFilter); }
    }
    updatedFilterList?.push(updatedFilterObj);
  }

  const selectedList = new Set([...filterStore.updatedFilterIds, selectedFilterId]);
  const updatedPanelList = [...updatedFilterObj?.delinkedFilterIds, ...getUpdatePanels(filterStore?.updatePanelList, updatedFilterList, payload?.ID)];
  return ({
    ...filterStore,
    selectedFilter: {
      ID: '',
      Label: '',
    },
    latestModifiedFilterId: selectedFilterId,
    dynamicGlobalFilters: [...updatedFilterList],
    updatedFilterIds: Array?.from(selectedList),
    updatePanelList: updatedPanelList,
  });
};

// get the updated dashboard config based on the filterStore changes for ex. delete filter, apply filters.
// @filterStore : Updated filter store
// @DashboardConfig : stored dashboard config
export const getUpdatedDashboardConfig = (filterStore: IFilterStore, dashboardConfig: IDashboardConfig) => {
  let updatedDashboardConfig = { ...dashboardConfig };
  const dashbordPanels = isValidArray(updatedDashboardConfig?.Panels) && updatedDashboardConfig?.Panels;
  // Reset the dashboard panel filter mapping and recreate with updated filter list
  dashbordPanels.forEach((panel) => {
    panel.Source.GlobalFiltersMapping = {};
  });
  const filterList = filterStore?.dynamicGlobalFilters;

  const dashboardFilters: IFilterItem[] = [];

  const isFilterAppliedAndHidden = (filter: IFilterItem) => filter?.IsApplied && filter?.hide;

  // Remove the applied filter from dashboard Config
  filterList?.forEach((filter) => {
    if (isFilterAppliedAndHidden(filter)) {
      const linkedPanelIds = Object.keys(filter?.linkedPanelFilter);
      dashbordPanels?.forEach((panel) => {
        if (linkedPanelIds.includes(panel.Id)) {
          delete panel.Source.GlobalFiltersMapping[filter.ID as keyof Object];
        }
      });
    }
    let saveFilterValues = {
      DefaultValue: filter?.filterData.FilterResponse,
      Metadata: filter?.Metadata,
    };
    if (filter.Type === FilterType.UserMultiSelect) {
      const userFilter = dashboardConfig?.Filters?.find((db: IFilterConfig) => db.ID === filter.ID);
      saveFilterValues = {
        DefaultValue: filter?.filterData?.FilterResponse?.UserIds,
        Metadata: { ...filter?.Metadata, UserDropdownSelectType: filter?.filterData?.FilterResponse?.Type },
      };
    }

    if (!filter.hide && !filter.alwaysHidden) {
      dashboardFilters?.push({
        Label: filter?.Label,
        Type: filter?.Type,
        IsPartitionColumn: filter?.IsPartitionColumn,
        ID: filter?.ID,
        JoinID: filter?.JoinID,
        ...saveFilterValues,
      });
    }

    const linkedPanelIds = (isValidObjectAndNotEmpty(filter?.linkedPanelFilter) && Object?.keys(filter?.linkedPanelFilter)) || [];

    if (!filter.hide && !filter.alwaysHidden && isValidArrayAndNotEmpty(linkedPanelIds)) {
      linkedPanelIds?.forEach((panelId) => {
        dashbordPanels?.forEach((panel) => {
          if (panelId === panel?.Id) {
            panel.Source.GlobalFiltersMapping = {
              ...panel.Source.GlobalFiltersMapping,
              [filter.ID]: filter.linkedPanelFilter[panelId],
            };
          }
        });
      });
    }
  });

  updatedDashboardConfig = {
    ...updatedDashboardConfig,
    Filters: dashboardFilters,
    Panels: dashbordPanels,
  };

  return updatedDashboardConfig;
};

// On delete we update the flag hide as true and update the filter store
// @filterStore: dashboard filter store
// @payload: deleted filter Item
export const getUpdatedFilterJsonOnDelete = (filterStore : IFilterStore, payload:IFilterItem): IFilterStore => {
  const selectedFilterId = payload?.ID;

  let updatedDynamicFilter : IFilterItem[] = cloneDeep(filterStore?.dynamicGlobalFilters);

  if (!payload.IsApplied) { // unapplied filters need to be removed from the dynamic filters list
    updatedDynamicFilter = filterStore?.dynamicGlobalFilters.filter((item) => item.ID !== selectedFilterId);
  } else {
    updatedDynamicFilter.forEach((item) => {
      if (item.ID === payload?.ID) {
        item.hide = true;
      }
    });
  }
  const selectedList = new Set([...filterStore.updatedFilterIds, selectedFilterId]);

  return ({
    ...filterStore,
    selectedFilter: {
      ID: '',
      Label: '',
    },
    dynamicGlobalFilters: updatedDynamicFilter,
    updatedFilterIds: Array.from(selectedList),
    updatePanelList: getUpdatePanels(filterStore.updatePanelList, filterStore.dynamicGlobalFilters, payload.ID),
  });
};

// get all the panelIds which are linked to changed filter
// @panelList : Array of panelIds which are impacted due to filter change
// @filterList : Stored filter list
// @filterId: Changed filter Id
export const getUpdatePanels = (panelList: string[], filterListItems: IFilterItem[], filterID?: string) => {
  let updatedPanels = [...panelList];
  const filterList = cloneDeep(filterListItems);

  if (filterID) {
    const filter = filterList?.find((item) => item.ID === filterID);
    if (filter && filter.IsApplied) { updatedPanels = [...updatedPanels, ...Object.keys(filter?.linkedPanelFilter)]; }
  } else {
    filterList.forEach((filter) => {
      if (!filter.IsApplied && !filter.alwaysHidden) {
        updatedPanels = [...updatedPanels, ...Object.keys(filter?.linkedPanelFilter)];
      }
    });
  }

  return updatedPanels;
};

// update the filter Applied flag for all the newely created filter and return the new filter list
// @filterList : Updated filter List
export const getUpdatedFilterListOnSave = (state: IDashboardState) => {
  const filterList = state?.filterStore?.dynamicGlobalFilters;

  const updatedFilterList: IFilterItem[] = [];

  if (isValidArray(filterList)) {
    filterList?.filter((item) => !item.hide)?.forEach((item: IFilterItem) => {
      if (!item?.IsApplied) {
        item.IsApplied = true;
      }
      const statusChanged = false;
      if (item.Type === FilterType.UserMultiSelect) {
        const userFilter = state.dashboardConfig?.Filters?.find((db: IFilterConfig) => db.ID === item.ID);
      }
      updatedFilterList.push(item);
    });
  }

  return updatedFilterList;
};

// Update the hide flag if a filter is not saved and panel is closed and reture the new filter list
// @filterList: Updated filter List
export const getUpdatedFilterOnClosePanel = (filters: IFilterItem[]) => {
  const updatedFilters = isValidArray(filters) && filters.filter((item) => item.IsApplied);
  updatedFilters.forEach((filter) => {
    if (filter.hide) filter.hide = false;
  });
  return updatedFilters;
};

// On Save/Apply Operation of filters we update the dashboard config and the filter store
// @state: Stored Dashboard state
export const getDashboardBuilderSaveConfigPayload = ((state:IDashboardState) => {
  const payload = {
    panelPayloads: transformDashboardConfigToPanelPayload(state, getUpdatedDashboardConfig(state.filterStore, state.dashboardConfig), true, state.filterStore.dynamicGlobalFilters),
    filterStore: {
      ...state.filterStore,
      updatePanelList: getUpdatePanels(state?.filterStore?.updatePanelList, state?.filterStore?.dynamicGlobalFilters),
      dynamicGlobalFilters: [...getUpdatedFilterListOnSave(state)],
      staticGlobalFilters: cloneDeep(getUpdatedFilterListOnSave(state)),
    },
    dashboardConfig: { ...getUpdatedDashboardConfig(state.filterStore, state.dashboardConfig) },
    loadFilterdAnimation: true,
    triggerCalls: true,
  };
  return payload;
});
// On Add widget operation we check for the string fields and update the isPartitionPanel flag with the partitionfilterIds
// @reportMappedPartitionColumns: Object store panelId as key and Object of partitionFilterId and isPartitioned boolean as value
// @reportConfig: Linked report config of newely added widget
// @panelId: PanelId of newely created panel
export const isPanelAppliedPartitionedColumn = (reportMappedPartitionColumns: IReportMappedColumns, reportColumns: IColumn[], panelId: string, reportConfigMetaData?: IConfigMetaData) : IReportMappedColumns => {
  const widgetPartitionedColumn: boolean = !!reportColumns?.find(
    (col) => col.Props?.Dimension?.DimensionProp?.DataType === ColumnDataTypes.String && !col.Props?.Dimension?.DimensionProp?.Props.BinningType
        && col?.Props.Dimension.Applied === DimensionStatus.Applied,
  );
  const widgetpartitionedColumns : IReportMappedColumns = {
    [panelId]: {
      isPartitioned: widgetPartitionedColumn,
      partitionFilterIds: reportConfigMetaData ? [...reportConfigMetaData?.filterMetaInfo?.partitionColumnFilterIds] : reportMappedPartitionColumns[panelId]?.partitionFilterIds,
    },
  };
  return { ...reportMappedPartitionColumns, ...widgetpartitionedColumns };
};

// On Selection of report generate the selected widget data with the selected report info and update the state.
// @reportWidgetPayload - Contains the config of selected Report for the widget.
export const getWidgetData = (reportWidgetPayload: IReportWidget) => {
  const updatedWidgetData: IReportWidget = {
    reportData: reportWidgetPayload?.reportData,
    rawReportConfig: reportWidgetPayload?.rawReportConfig,
    mappedMetaFields: getMappedMetaFields(
      reportWidgetPayload.rawReportConfig.Columns,
      reportWidgetPayload.reportData.Raw.Data,
    ),
    widgetSettings: reportWidgetPayload?.widgetSettings,
  };

  return updatedWidgetData;
};

// On call of fetch panel data will update the loading state of all the linked panels
// @panelIds - Contains the linked panel Id.
export const getStateOnRequestPanelDataLoad = (
  state: IDashboardState,
  panelIds: Array<string>,
) => {
  const newState = state;
  panelIds.forEach((id) => {
    newState.dashboardData[id] = {
      ...newState.dashboardData[id],
      loadingState: LoadingStatus.Loading,
    };
  });
  return { ...newState.dashboardData };
};

export const setInitalPayloadData = (payload: IDashboardConfig) => {
  const panelIds: string[] = payload.Panels.map((panel: IPanel) => panel.Id);
  const dashboardInitialdata: Obj = {};
  panelIds.forEach((id) => {
    dashboardInitialdata[id] = {
      Raw: null,
      Visualization: null,
      loadingState: LoadingStatus.Loading,
      saveTempViewLoadingStatus: null,
      saveTempDrilldownLoadingStatus: null,
      ddStateId: null,
    };
  });
  return dashboardInitialdata;
};

// Convert the Dashboard config panels to panelPayload which is being used to optimize the fetch data calls
// @state: Dashboard State
// @DashboardConfig: Selected Dashboard Config
// @isDashboardBuilder: boolean to set the context of dashboard Builder
export const transformDashboardConfigToPanelPayload = (
  state: IDashboardState,
  dashboardConfig: IDashboardConfig,
  isDashboardBuilder: boolean,
  filters?:IFilterItem[],
) => {
  const panelPayloads: Array<IPanelPayloads> = [];
  const filtered = getFiltersPayload(filters);
  dashboardConfig.Panels.forEach((panel: IPanel) => {
    const { Source, Id } = panel;
    let FeatureContext = panel.FeatureContext;
    if (FeatureContext !== undefined && !FeatureContext) {
      FeatureContext = FeatureContextType.Sales;
    }
    const {
      SourceId,
      SourceType,
      PageSize,
      GlobalFiltersMapping,
      Url = '',
    } = Source;
    const duplicatePanelSource = panelPayloads.find(
      (panelPayload: IPanelPayloads) => panelPayload.Source.SourceId === SourceId
        && isEqual(panelPayload.MappingFilters, GlobalFiltersMapping),
    );
    if (duplicatePanelSource) {
      duplicatePanelSource.Target.push(Id);
      duplicatePanelSource.Source.PageSize = Math.max(
        PageSize,
        duplicatePanelSource.Source.PageSize,
      );
    } else {
      panelPayloads.push({
        Target: [Id],
        Source: {
          SourceType,
          SourceId,
          Filters: getGlobalFilter(filtered, panel),
          PageSize,
          Url,
          FeatureContext,
        },
        FiltersMetaData: getFiltersMetaData(filtered, filters, panel),
        MappingFilters: GlobalFiltersMapping,
        isFiltered: isPanelsFiltered(
          filters,
          state.filterStore.staticGlobalFilters,
          GlobalFiltersMapping,
        ) && !isDashboardBuilder,
        FiltersApplied: isDashboardBuilder ? filterAppliedOnDashboardBuilder(filters, GlobalFiltersMapping) : filterAppliedOnPanels(
          state.filterStore.staticGlobalFilters,
          state.dashboardConfig.Filters,
          GlobalFiltersMapping,
        ),
      });
    }
  });

  return [...panelPayloads];
};

// On Success of fetch data call we update the panel data loading state of all the linked panels
// @state: Dashboard State
// @payload: This Object contains fetch data response, linked panelIds, load data status
export const getStateOnRequestPanelDataSuccess = (
  state: IDashboardState,
  payload: IRequestPanelDataSuccessPayload,
) => {
  const { response, panelIds, panelStatusCode } = payload;
  const newState = { ...state };

  const columns = response?.Visualization?.Columns;
  const isPivot = !!columns?.find(
    (item) => item.Props?.Dimension?.DimensionMode === DimensionMode.ColumnGroup,
  );
  panelIds.forEach((panelId: string) => {
    const pageSize = state.dashboardConfig.Panels?.find((item: IPanel) => item.Id === panelId)
      ?.Source?.PageSize || 0;
    newState.dashboardData[panelId] = {
      ...newState.dashboardData[panelId],
      Raw: {
        ...response?.Raw,
        Data:
          (isPivot
            ? response?.Raw?.Data
            : response?.Raw?.Data?.slice(0, pageSize)) || [],
      },
      Visualization: {
        ...response?.Visualization,
      },
      loadingState: LoadingStatus.Success,
      panelStatusCode,
    };
    newState.filterStore = {
      ...newState.filterStore,
      panelMappedPartitionColumns: isPanelAppliedPartitionedColumn(newState?.filterStore?.panelMappedPartitionColumns, columns, panelId),
    };
  });
  newState.dashboardData = { ...newState.dashboardData };

  return newState;
};

// On Failure of fetch data call we set the loading status of all linked panels
// @state: Dashboard state
// @panelIds: Array of linked panelIds
export const getStateOnRequestPanelDataFailure = (
  state: IDashboardState,
  panelIds: Array<string>,
  status:number,
) => {
  const newState = { ...state };
  panelIds.forEach((panelId: string) => {
    newState.dashboardData[panelId] = {
      ...newState.dashboardData[panelId],
      loadingState: LoadingStatus.Error,
      panelStatusCode: status || statusCodes.Unauthorized,
    };
  });
  return { ...newState.dashboardData };
};

// On change of filter value we update the filter value in the store
// @state: dynamicFilterList
// @payload: changed filter Id and  updated filter value
export const getStateOnFilterChange = (
  state: Array<IFilterItem>,
  { FilterID, FilterValue }: IFiltersOnchange,
) => {
  const newState = cloneDeep(state);
  newState.forEach((filter: IFilterItem, index) => {
    if (FilterID === filter.ID) {
      newState[index].filterData.FilterResponse = FilterValue;
    }
  });
  return newState;
};

// On layout change we update the dashboard config with the updated layout
// @payload: This is the payload which we get from react-grid-layout contains the id and breakpoint for that panel
export const getStateOnUpdateDashboardConfig = (
  state: IDashboardState,
  payload: any,
) => {
  const newState = { ...state };
  if (isValidArray(payload)) {
    payload?.forEach((newPanelConfig: any, i: number) => {
      const currentPanelConfig = newState.dashboardConfig.Panels[i];
      const panelType = currentPanelConfig.Target.Visualization.Type;
      const width = getPanelWidth(newPanelConfig.w, panelType);
      const height = getPanelHeight(newPanelConfig.h, panelType);
      const newGridLayout = {
        ...state.dashboardConfig.Panels[i].Target.GridLayout,
        x: newPanelConfig.x,
        y: newPanelConfig.y,
        w: width,
        h: height,
      };
      newState.dashboardConfig.Panels[i].Target.GridLayout = { ...newGridLayout };
    });
  }

  return { ...newState.dashboardConfig };
};

export const getStateOnRequestDashboardConfigSuccess = (
  dashboardConfig: IDashboardConfig,
  isEditMode: boolean,
) => {
  const newPanels = dashboardConfig.Panels.map((panelConfig: IPanel) => {
    const panelType = panelConfig?.Target?.Visualization?.Type;
    const defaultDimensions = getDefaultDimensions(panelType);
    const width = getPanelWidth(panelConfig.Target.GridLayout.w, panelType);
    const height = getPanelHeight(panelConfig.Target.GridLayout.h, panelType);
    const panelProperties = panelType === VisualizationTypes.Table ? { ...getDashboardWidgetVisualization(panelConfig?.Target?.Visualization?.Builder?.Properties) } : panelConfig?.Target?.Visualization?.Builder?.Properties;
    const newTarget = {
      ...panelConfig.Target,
      GridLayout: {
        ...panelConfig.Target.GridLayout,
        w: width,
        h: height,
        i: panelConfig.Id,
        isDraggable: isEditMode,
        isResizable: isEditMode,
        ...defaultDimensions,
      },
    };
    return {
      ...panelConfig,
      Target: { ...newTarget, Visualization: { ...panelConfig.Target.Visualization, Builder: { ...panelConfig.Target.Visualization.Builder, Properties: panelProperties } } },
    };
  });
  const newDashboardConfig = {
    ...dashboardConfig,
    Panels: [...newPanels],
  };
  return { ...newDashboardConfig };
};

export const getStateOnSaveTempViewRequest = (
  state: IDashboardState,
  panelId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  newDashboardData[panelId].saveTempViewLoadingStatus = LoadingStatus.Loading;
  return { ...newState.dashboardData };
};

export const getStateOnSaveTempViewSuccess = (
  state: IDashboardState,
  panelId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  newDashboardData[panelId].saveTempViewLoadingStatus = LoadingStatus.Success;
  return { ...newState.dashboardData };
};

export const getStateOnSaveTempViewFailure = (
  state: IDashboardState,
  panelId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  newDashboardData[panelId].saveTempViewLoadingStatus = LoadingStatus.Error;
  return { ...newState.dashboardData };
};

export const getUpdatedFilters = (state: IDashboardState, updatedFilters: IFilterItem[]) => {
  const newState = cloneDeep(state);
  const newUpdatedFilters: string[] = [];
  const staticFilters: IFilterItem[] = newState.filterStore.staticGlobalFilters;
  if (isValidArray(updatedFilters) && updatedFilters.length) {
    updatedFilters.forEach((filter, index) => {
      if (!isEqual(filter, staticFilters[index])) {
        newUpdatedFilters.push(filter.ID);
      }
    });
  }
  return newUpdatedFilters;
};

export const getStateOnSaveTempDrilldownRequest = (
  state: IDashboardState,
  panelId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  newDashboardData[panelId].saveTempDrilldownLoadingStatus = LoadingStatus.Loading;
  newState.dashboardData = newDashboardData;
  return { ...newState.dashboardData };
};

export const getStateOnSaveTempDrilldownSuccess = (
  state: IDashboardState,
  panelId: string,
  ddStateId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  newDashboardData[panelId].saveTempDrilldownLoadingStatus = LoadingStatus.Success;
  newDashboardData[panelId].ddStateId = ddStateId;
  newState.dashboardData = newDashboardData;
  return { ...newState.dashboardData };
};

export const getStateOnSaveTempDrilldownFailure = (
  state: IDashboardState,
  panelId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  newDashboardData[panelId].saveTempDrilldownLoadingStatus = LoadingStatus.Error;
  newState.dashboardData = newDashboardData;
  return { ...newState.dashboardData };
};

export const getStateOnResetRedirectionStatus = (
  state: IDashboardState,
  panelId: string,
) => {
  const newState = { ...state };
  const newDashboardData = newState.dashboardData;
  if (newDashboardData[panelId]) {
    newDashboardData[panelId].saveTempDrilldownLoadingStatus = null;
    newDashboardData[panelId].saveTempViewLoadingStatus = null;
  }

  return { ...newState.dashboardData };
};

// On Add widget operation will fetch the applied filters and update the store against the report id.
// @reportMappedFilters: Object contains the report Id as key and applied filters as value
// reportConfig : report config of added widget
export const getUpdatedAvailableFilters = (reportMappedFilters: IReportMappedFilters, reportConfig: IReportConfig) : IReportMappedFilters => {
  let widgetFilterConfig : IFilterConfig[] = getFilterConfigFromTemplate(reportConfig);
  widgetFilterConfig = removeObjectWithSameId(widgetFilterConfig, 'ID') as IFilterConfig[];

  const reportIds = Object.keys(reportMappedFilters);
  if (reportIds.includes(reportConfig.ReportConfigId)) {
    return { ...reportMappedFilters };
  }
  const widgetavailableFilters : IReportMappedFilters = {
    [reportConfig.ReportConfigId]: widgetFilterConfig,
  };
  return { ...reportMappedFilters, ...widgetavailableFilters };
};

// Update the filterData for the particular filter Item after api success
// @filterList : Stored Filter List
// @filterId: updated filterId
// @filterResponse: Updated Filter Response
export const getFilterLoadSuccess = (filterList: IFilterItem[], filterId: string, filterResponse: IFilterResponse, shouldUpdate: boolean = true) => {
  if (isValidArray(filterList) && filterList.length && shouldUpdate) {
    filterList.forEach((item) => {
      if (item.ID === filterId) {
        item.filterData = filterResponse;
      }
    });
  }
  return filterList;
};

const updateFilterList = (filter: IFilterItem, updatedFilterList: IFilterItem[], defaultPreAppliedFilter: IFilterItem) => {
  if (filter.Metadata.FilterKey === defaultGroupMetaDataKey) {
    filter.filterLinkedKey = defaultGroupFilterKey;
    if (!updatedFilterList.includes(defaultGroupFilter)) { updatedFilterList.push(defaultGroupFilter); }
  } else {
    filter.filterLinkedKey = defaultPreAppliedKey;
    if (!updatedFilterList.includes(defaultPreAppliedFilter)) { updatedFilterList.push(defaultPreAppliedFilter); }
  }
};

// On Config Success update filter data item
// @filterList : Stored Filter List
// @filterMetaData : FilterMetaData User Filter Type
export const getFiltersOnConfigSuccess = (filterList: IFilterItem[]) => {
  const updatedFilterList: IFilterItem[] = cloneDeep(filterList);
  if (isValidArray(updatedFilterList) && filterList.length > 0) {
    updatedFilterList?.forEach((item, index) => {
      let value = null;
      if (item.Type === FilterType.UserMultiSelect) {
        if (item.Metadata?.UserDropdownSelectType && item.DefaultValue) {
          value = {
            Type: item.Metadata?.UserDropdownSelectType,
            UserIds: item.DefaultValue,
          };
        }
        item.Status = item.Metadata?.Status;
      } else {
        value = item.DefaultValue;
      }
      updatedFilterList[index].filterData = new FilterFetchUtility(null).GetDefaultSingleFilter(
        item,
        value,
      );
    });
  }
  if (isValidArray(updatedFilterList)) {
    const defaultPreAppliedFilter = getDefaultPreAppliedFilter();
    updatedFilterList?.forEach((filter) => {
      if (filter.Type === FilterType.UserMultiSelect) {
        updateFilterList(filter, updatedFilterList, defaultPreAppliedFilter);
      }
    });
  }

  return updatedFilterList;
};

// Fetch the filterMetaData is User Type filter is applied
// @globalFilters: Stores the globalFiltersMapping of panel
// @dynamicGlobalFilters: Array of all the filters
export const getFiltersMetaData = (globalFilters: Obj, dynamicGlobalFilters: IFilterItem[], panel:IPanel) : ObjModel.Obj => {
  const filtersMetaData = {};
  const filters = Object.keys(globalFilters);
  const globalFilterMapping = isValidObjectAndNotEmpty(panel?.Source?.GlobalFiltersMapping) ? Object?.keys(panel?.Source?.GlobalFiltersMapping) : [];
  if (isValidArray(dynamicGlobalFilters) && isValidArray(filters) && filters.length > 0) {
    dynamicGlobalFilters?.forEach((item: IFilterItem) => {
      if (item.Type === FilterType.UserMultiSelect && filters.includes(item.ID) && globalFilterMapping.includes(item.ID)) {
        filtersMetaData['UserDropdownSelectType' as keyof Object] = item?.filterData?.FilterResponse?.Type;
        filtersMetaData['Status' as keyof Object] = item?.Metadata?.Status || userStatusEnum.Active;
      }
    });
  }
  return filtersMetaData;
};

export const getUpdatedFilterChangeData = (state:IDashboardState, payload:IFiltersOnchange) => {
  const newState = cloneDeep(state);
  const updatedFilterList = getStateOnFilterChange(newState.filterStore.dynamicGlobalFilters, payload);
  return {
    ...state.filterStore,
    dynamicGlobalFilters: updatedFilterList,
    updatedFilterIds: getUpdatedFilters(state, updatedFilterList),
    updatePanelList: getUpdatePanels(state.filterStore.updatePanelList, updatedFilterList, payload?.FilterID),
  };
};

// Update the dynamicGlobalFilters & staticGlobalFilters of the filterStore to handle updation of IsMasked Flag of a particular filterObj
// @state : Filter Store Object
// @filterId: updated filterId
export const getUpdatedFilterInfo = (state:IFilterStore, filterId:string) => ({
  ...state,
  dynamicGlobalFilters: getUpdatedArrayOfFilters(state.dynamicGlobalFilters || [], filterId),
  staticGlobalFilters: getUpdatedArrayOfFilters(state.staticGlobalFilters || [], filterId),
});
// to update the IsMasked flag of particular filterObj in a filterList
// @filterList: the List of filterObjects
// @filterId: the filterId for which the IsMasked Flag needs to be changed
export const getUpdatedArrayOfFilters = (filterList:IFilterItem[], filterId:string) => {
  const updatedFilterList = cloneDeep(filterList);
  if (updatedFilterList.length) {
    updatedFilterList.forEach((item) => {
      if (item.ID === filterId) {
        item.IsMasked = true;
        item.filterData.shouldLoad = false;
      }
    });
  }

  return updatedFilterList;
};
// Format the getFiltersList response => flattening of filterObj and removing unused keys
// @filterList: response of the getFiltersList api
// returns: Object containing the report Id as key and applied filters as value
export const getFormattedReportMappedFilters = (filterList:IFilterListResponse) :IReportMappedFilters => {
  const updatedReportMappedFilters = {} as IReportMappedFilters;
  if (isValidObjectAndNotEmpty(filterList.ReportFilters)) {
    Object.keys(filterList.ReportFilters).forEach((reportId:string) => {
      const filtersArray = filterList.ReportFilters[reportId]?.Filters || [];
      const PartitionColumnIds = filterList.ReportFilters[reportId]?.PartitionColumnIds || [];
      const formattedFiltersList = filtersArray?.map((filter:IFilterItem) => {
        const updatedObj = { ...filter, ...filter?.Render };
        updatedObj.IsPartitionColumn = PartitionColumnIds.includes(filter.ID);
        delete updatedObj.Render;
        return updatedObj;
      });
      updatedReportMappedFilters[reportId] = formattedFiltersList;
    });
  }
  return updatedReportMappedFilters;
};
// To get the getPartitionColumnsIDs information for a dashboard
// @filterList: response of the getFiltersList api
// @currentPanelMappedPartitionColumns: updated PanelMappedPartitionColumns, obj containing key as panelId and value as an object
// @panelList : list of available panels from dashboard config
// returns: updated PanelMappedPartitionColumns
export const getPartitionColumnIds = (filterList:IFilterListResponse, panelList:IPanel[], currentPanelMappedPartitionColumns:IReportMappedColumns) :IReportMappedColumns => {
  const updatedPartitionColumnsData = cloneDeep(currentPanelMappedPartitionColumns);
  if (isValidObjectAndNotEmpty(filterList?.ReportFilters) && isValidArrayAndNotEmpty(panelList)) {
    panelList.forEach((panel:IPanel) => {
      const sourceId = panel.Source.SourceId;
      const PartitionColumnIds = filterList.ReportFilters[sourceId]?.PartitionColumnIds || [];
      updatedPartitionColumnsData[panel.Id] = {
        ...updatedPartitionColumnsData[panel.Id],
        partitionFilterIds: PartitionColumnIds,
      };
    });
  }
  return updatedPartitionColumnsData;
};
// Initialise the filterStore with updated filterInfo by utilizing getFilterList response and getDashboardConfig response
// @state: Filter Store Object
// @filterListResponse: response of the getFiltersList api
// @panelList : list of available panels from dashboard config
// returns: FilterStore Object with updated values for dynamicGlobalFilters,staticGlobalFilters, reportMappedFilters
export const getInitialisedFilterStoreForEdit = (state:IFilterStore, filterListResponse:IFilterListResponse, panelList:IPanel[]) :IFilterStore => {
  const newState = cloneDeep(state);
  const globalFiltersList = newState.dynamicGlobalFilters;
  const updatedFilterList:IFilterItem[] = [];

  if (isValidArrayAndNotEmpty(globalFiltersList)) {
    globalFiltersList.forEach((filterItem) => {
      const linkedFilterPanelObj:IFilterLinkInfo = {};
      panelList.forEach((panel) => {
        const globalFilterMapping = panel?.Source?.GlobalFiltersMapping as ObjModel.Obj;
        if (isValidObjectAndNotEmpty(globalFilterMapping) && globalFilterMapping[filterItem.ID] !== undefined) {
          linkedFilterPanelObj[panel.Id] = globalFilterMapping[filterItem.ID];
        }
      });
      const updatedFilterObj:IFilterItem = {
        ...filterItem,
        linkedPanelFilter: linkedFilterPanelObj,
        IsApplied: filterItem?.IsApplied === false ? filterItem?.IsApplied : true,
        IsEditing: filterItem?.IsEditing || false,
        hide: filterItem?.hide || false,
        filterLoadData: filterItem?.filterLoadData || false,
        alwaysHidden: filterItem?.alwaysHidden || false,
      };
      updatedFilterList.push(updatedFilterObj);
    });
  }
  return {
    ...newState,
    dynamicGlobalFilters: updatedFilterList,
    staticGlobalFilters: updatedFilterList,
    reportMappedFilters: getFormattedReportMappedFilters(filterListResponse),
    panelMappedPartitionColumns: getPartitionColumnIds(filterListResponse, panelList, newState?.panelMappedPartitionColumns),
  };
};

// To get the DataType field information for a particular filterId
// @selectedFilter: selected filter item for which we need to find DataType
// @reportMappedFilters: Object contains the report Id as key and applied filters as value
// @panelList : list of available panels from dashboard config
// returns: dataType of the selectedFilter
export const setDataTypeForFilterItem = (selectedFilter:IFilterItem, panelList:IPanel[], reportMappedFilters:IReportMappedFilters) :ColumnDataTypes => {
  const panelId = isValidObjectAndNotEmpty(selectedFilter.linkedPanelFilter) && Object.keys(selectedFilter.linkedPanelFilter)[0];
  const sourceId = isValidArrayAndNotEmpty(panelList) && panelList.find((item:IPanel) => item.Id === panelId)?.Source?.SourceId;
  const selectedFilterId = selectedFilter.linkedPanelFilter[panelId];
  const reportMappedFiltersList = isValidObjectAndNotEmpty(reportMappedFilters) && Object.values(reportMappedFilters[sourceId as keyof Object]);
  const selectedFilterItem = isValidArrayAndNotEmpty(reportMappedFiltersList) && reportMappedFiltersList.find((filter) => filter?.ID === selectedFilterId);
  return selectedFilterItem?.DataType;
};
// Updates the panel isFiltered flag on close of dashboard filter list panel
// @panelPayloads: Array of object which contains current panelPayload
export const updatePanelPayloadOnClose = (panelPayloads: IPanelPayloads[]) => {
  const newPanelPayload = cloneDeep(panelPayloads);

  newPanelPayload.forEach((panel: IPanelPayloads) => {
    if (panel.isFiltered) {
      panel.isFiltered = false;
    }
  });

  return newPanelPayload;
};
// To updated the linkedPanelFilter for a each filter when a panel is removed -> (delinking)
// @filterList: the List of filterObjects
// @selectedFilterIdList: filterIdList of the panel getting removed.
// @panelId : list of available panels from dashboard config
// returns: updatedFilterList after removing deleted panel information
export const getUpdatedFilterListForWidgetDelete = (filterList:IFilterItem[], selectedFilterIdList:string[], panelId:string) :IFilterItem[] => filterList.map((filterObj:IFilterItem) => {
  if (selectedFilterIdList.includes(filterObj.ID)) {
    const { [panelId]: _, ...updatedLinkedFilter } = filterObj.linkedPanelFilter;
    return {
      ...filterObj,
      linkedPanelFilter: { ...updatedLinkedFilter },
    };
  }
  return filterObj;
});

// To get updated filterStore when a panel is deleted
// @state: FilterStore Object
// @panel : deleted panelObj
// returns: updatedFilterStore after removing deleted panel information
export const getUpdatedFilterStoreOnWidgetDelete = (state:IFilterStore, panel:IPanel) : IFilterStore => {
  const newState = cloneDeep(state);
  let updatedDynamicFilterList = newState.dynamicGlobalFilters;
  let updatedStaticFilterList = newState.staticGlobalFilters;

  if (isValidObjectAndNotEmpty(panel?.Source?.GlobalFiltersMapping)) {
    const filterList = Object.keys(panel.Source.GlobalFiltersMapping);
    updatedDynamicFilterList = getUpdatedFilterListForWidgetDelete(newState.dynamicGlobalFilters, filterList, panel.Id);
    updatedStaticFilterList = getUpdatedFilterListForWidgetDelete(newState.staticGlobalFilters, filterList, panel.Id);
  }

  return {
    ...newState,
    dynamicGlobalFilters: updatedDynamicFilterList,
    staticGlobalFilters: updatedStaticFilterList,
  };
};

export const getDashboardWidgetVisualization = (properties: ObjModel.Obj) => {
  const updatedProperties = { ...properties };

  return {
    ...updatedProperties,
    showSummary: !(IChartAttributesKeys.showSummary in updatedProperties) ? true : updatedProperties?.showSummary,
  };
};
