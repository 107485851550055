const light = {
  labelColor: '#637381',
  border: '#006fd6',
  loaderColor: '#309AFC',
  fullLoaderBar1: '#015CCD',
  fullLoaderBar2: '#E2ECF9',
  fullLoaderBar3: '#3980D8',
  fullLoaderBar4: '#C6DAF3',
  white: '#ffffff',
  noDataColor: '#898989',
  paginationBorder: '#DFE3E8',
  paginationRecordsSeparator: '#C9d3DB',
  searchBorder: '#C4CDD5',
  searchHoverBorder: '#1172D3',
  searchBoxTextColor: '#919EAB',
  searchButtonBackground: '#538AC5',
  backgroundColor: '#F4F6F8',
  paginationPrev: '#000000',
  paginationNext: '#adbac6',
  arrowIcon: '#adbac7',
  reactOnOptionSelect: '#E5F0FB',
  reactSelectScrollBar: '#D0CECE',
  rawDataHeader: '#97a2ab',
  rawDataButton: '#e8e9ea',
  boxShadow: '#00000029',
  backButtonBorder: '#E0E3E6',
  sideNavBarBorder: '#00000014',
  navSectionIcon: '#ed7b24',
  headerBackgroundColor: '#012240',
  avtarColor: '#F5B129',
  primaryColor: '#212B36',
  secondaryColor: '#454F5B',
  dangerColor: '#DE323B',
  expandListButtonHoverColor: '#084E96',
  darkOrangeColor: '#F38037',
  opportunityColor: '#3bb345',
  taskColor: '#46BFC9',
  userColor: '#e32c8d',
  filterFocusColor: '#1360B5',
  disablefilterColor: '#7EB6EA',
  clearFilterColor: '#F9FAFB',
  filterBackgroundColor: '#0064C0',
  borderColor: '#CAD3DB',
  inactiveColor: '#A9A9A9',
  warningColor: '#D1331B',
  disclaimerColor: '#FD6966',
  itemColor: '#F5802A',
  dangerIconColor: '#BF2422',
  datePickerWarningColor: '#172B4D',
  wildSand: '#F5F5F5',
  dustyGray: '#999999',
  gallery: '#efefef',
  chartBackground: '#0000',
  chartBaseColor: '#4D5A6D',
  blueBorderColor: '#116FD6',
  dashboardHoverColor: 'rgba(63, 133, 247, 0.1)',
  animationGreenColor: '#189F3F',
  lightGray: '#ADADAD',
  dividerColor: '#63738180',
  dangerColorBg: '#feeff1',
  animationGreenColorBg: '#F0FFF5',
  skyBlue: 'rgba(0, 111, 214, 0.1)',
  itemColorBg: '#FFF6F0',
  subTitleBg: 'rgba(8, 78, 150, 0.1)',
  scheduledReportColor: '#3E4589',
  spinnerLoaderColor: '#f3f3f3',
  whiteBackground: '#F8F8F8',
  lsqDataHeader: '#444444',
  lsqText: '#666666',
  lsqText2: '#888888',
  lsqButton: '#DDDDDD',
  lsqToastBackground: '#F6C7C6',
  lsqToastBoard: '#CE7471',
  baseDatePickerBorder: '#DFE3E880',
  purpleColor: '#A456BA',
  lsqHover: '#95C3F8',
  lsqBorder: '#C9C9C9',
  lsqSelected: '#438AEC',
  lsqAltColor: '#FDFDFD',
  lsqAltColor2: '#F9F9F9',
  lsqSelHover: '#FFF4EA',
  lsqIconFilled: '#FF9900',
  lsqTagCol: '#4F81BD',
  lsqBgCol: '#F0EFF0',
  lsqDeleteBtn: '#47A7FF',
  lsqSectionHover: '#FFB676',
  v1animationColor: '#7ED870',
  lsqDeleteCount: '#008000',
  lsqAllRptColor: '#6EDD29',
  backgroundColorSearchV1: '#F1F1F1',
  backgroundColorDropdown: '#F0F0F0',
  bgColorExpTooltip: '#FFF0F1',
  bannerFontColor: '#818181',
  bannerBgColor: '#F6F6F6',
  bannerButtonsHover: '#555555',
  dropdownBoxShadow: 'rgba(0, 0, 0, 0.16)',
  lineSeprator: 'rgba(173, 186, 199, 0.25)',
  leadsquaredIconBlue: '#0C9AFC',
  leadsquaredIconBlack: '#0C293D',
  leadsquaredLightBlue: '#99C5EF',
  customEntiyColor: '#781010',
  boxShadowColor: '#00000029',
  siennaColor: '#A65619',
  ticketEntityColor: '#ED7B24',
  mergeJobHighlightColor: '#297D30',
  customIconColor: '#BDC1E7',
  ticketActivityEntity: '#6D564A',
  rowFooterBackgroundColor: '#dde2eb',
  accountsEntity: '#91B256',
  accountsActivityEntity: '#674598',
  webContentEntity: '#DC7A6E',
  cornflowerBlueColor: '#006FD6',
  reactCalenderTileDisableColor: '#454f5b80',
  dangerHoverColor: '#BF2A32',
  tableHeaderBackgroundColor: '#F3F6F8',
  iconStroke: '#b9c4ce',
  premiumIconColor: '#FFB743',
  lightGreen: '#3BB345',
  selectedRowColor: '#e4f1fb',
  scrollbarColor: '#ADBAC7',
  backdropColor: 'rgba(0, 0, 0, 1)',
  paleBlue: '#E5F1FB',
  navHeaderBackgroundColor: '#084E961A',
  lsqMenuColor: '#0A1F43',
};

export default light;
