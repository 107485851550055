import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const SettingIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={fill}>
    <g clipPath="url(#clip0_39_21591)">
      <path d="M2.48296 4.55783C2.48296 4.51302 2.51929 4.47668 2.56411 4.47668H4.49465V6.50447H2.48296V4.55783Z" fill="white" />
      <path d="M13.8851 4.56064C13.8851 4.51582 13.8488 4.47949 13.804 4.47949H6.06486V6.50442H13.8851V4.56064Z" fill="white" />
      <path d="M15.3757 8.07043H6.06487V15.955H11.5182C13.6486 15.955 15.3757 14.228 15.3757 12.0976V8.07043Z" fill="white" />
      <path d="M2.48296 14.3813C2.48296 14.4262 2.51929 14.4625 2.56411 14.4625H4.49465V8.07007H2.48296V14.3813Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.992184 4.56792C0.992184 3.69814 1.69668 2.99304 2.56572 2.99304H13.8021C14.6712 2.99304 15.3757 3.69814 15.3757 4.56792V8.04026C15.3757 8.45227 15.0419 8.78625 14.6303 8.78625C14.2186 8.78625 13.8849 8.45227 13.8849 8.04026V4.56792C13.8849 4.52214 13.8478 4.48503 13.8021 4.48503H2.56572C2.51999 4.48503 2.4829 4.52214 2.4829 4.56792V14.3801C2.4829 14.4259 2.51999 14.463 2.56572 14.463H7.46764C7.8793 14.463 8.213 14.7969 8.213 15.209C8.213 15.621 7.8793 15.955 7.46764 15.955H2.56572C1.69668 15.955 0.992184 15.2499 0.992184 14.3801V4.56792Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.992184 4.56792C0.992184 3.69814 1.69668 2.99304 2.56572 2.99304H13.8021C14.6712 2.99304 15.3757 3.69814 15.3757 4.56792V6.49451C15.3757 7.36429 14.6712 8.06938 13.8021 8.06938H2.56572C1.69668 8.06938 0.992184 7.36429 0.992184 6.49451V4.56792ZM2.56572 4.48503C2.51999 4.48503 2.4829 4.52214 2.4829 4.56792V6.49451C2.4829 6.54029 2.51999 6.5774 2.56572 6.5774H13.8021C13.8478 6.5774 13.8849 6.54029 13.8849 6.49451V4.56792C13.8849 4.52214 13.8478 4.48503 13.8021 4.48503H2.56572Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.992184 4.56792C0.992184 3.69814 1.69668 2.99304 2.56572 2.99304H4.49068C5.35972 2.99304 6.06422 3.69814 6.06422 4.56792V14.3801C6.06422 15.2499 5.35972 15.955 4.49068 15.955H2.56572C1.69668 15.955 0.992184 15.2499 0.992184 14.3801V4.56792ZM2.56572 4.48503C2.51999 4.48503 2.4829 4.52214 2.4829 4.56792V14.3801C2.4829 14.4259 2.51999 14.463 2.56572 14.463H4.49068C4.53641 14.463 4.57349 14.4259 4.57349 14.3801V4.56792C4.57349 4.52214 4.53641 4.48503 4.49068 4.48503H2.56572Z" fill={fill} />
      <path d="M4.49494 4.48547H6.06515V14.4624H4.49494V4.48547Z" fill={fill} />
      <path d="M13.8851 6.50429V8.07055H2.48296V6.50429H13.8851Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.09 9.09781C14.5009 8.67638 15.167 8.67638 15.5779 9.09781L16.9619 10.5173C17.3728 10.9387 17.3728 11.622 16.9619 12.0434L11.3625 17.7865C11.1651 17.9888 10.8975 18.1025 10.6184 18.1025H9.23449C8.6534 18.1025 8.18232 17.6194 8.18232 17.0234V15.6039C8.18232 15.3178 8.29317 15.0433 8.49051 14.8409L14.09 9.09781Z" fill="white" />
      <path d="M15.5574 11.7739L14.9644 11.1816L10.0735 16.0782L10.6665 16.6705L15.5574 11.7739Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.9572 11.3671L10.6101 15.8089V16.2027H10.9955L15.3426 11.7608L14.9572 11.3671ZM14.3011 9.84358C14.6635 9.47336 15.2509 9.47337 15.6132 9.84358L16.8336 11.0906C17.1959 11.4608 17.1959 12.061 16.8336 12.4312L11.896 17.4764C11.7221 17.6542 11.4861 17.754 11.24 17.754H10.0197C9.50728 17.754 9.09188 17.3296 9.09188 16.806V15.5591C9.09188 15.3077 9.18963 15.0665 9.36365 14.8887L14.3011 9.84358Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9628 7.97811C16.2596 7.67478 16.7409 7.67478 17.0378 7.97811L18.7271 9.7043C19.0239 10.0076 19.0239 10.4995 18.7271 10.8029C18.4301 11.1062 17.9489 11.1062 17.6521 10.8029L15.9628 9.07664C15.6659 8.77331 15.6659 8.28146 15.9628 7.97811Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_39_21591">
        <rect width="18" height="14.7541" fill="white" transform="translate(1 3)" />
      </clipPath>
    </defs>
  </svg>
);

SettingIcon.defaultProps = {
  fill: 'var(--primaryColor)',
  width: 20,
  height: 20,
};

export default SettingIcon;
