import React from 'react';

type Props = {
    width?: number,
    height?: number,
    fill?: string,
}

const LSQMenuIcon = ({ width, height, fill }: Props) => (
  <svg
    style={{
      width, height,
    }}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H18C18.4142 6.25 18.75 6.58579 18.75 7C18.75 7.41421 18.4142 7.75 18 7.75H6C5.58579 7.75 5.25 7.41421 5.25 7Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H6C5.58579 12.75 5.25 12.4142 5.25 12Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.25 17C5.25 16.5858 5.58579 16.25 6 16.25H18C18.4142 16.25 18.75 16.5858 18.75 17C18.75 17.4142 18.4142 17.75 18 17.75H6C5.58579 17.75 5.25 17.4142 5.25 17Z" fill={fill} />
  </svg>
);

LSQMenuIcon.defaultProps = {
  fill: 'var(--lsqMenuColor)',
  width: 24,
  height: 24,
};

export default LSQMenuIcon;
