import store from 'redux-v2/store';
import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk } from '@reduxjs/toolkit';
import DashboardBuilderService from 'services/dashboard-builder.service';
import { PersonalizedDashboardCriteria, SaveTempViewCriteria, SingletonDashboardService } from 'services/dashboard.service';
import {
  IDashboardConfig,
  IFilterItem,
  IPanel,
  IPanelDataResponse, IReportWidget, IWidgetSettings, PanelDataFetchCriteria, SaveTempDrilldownCriteria,
} from 'core/models/dashboard';
import { AccessType, statusCodes } from 'core/constants/report';
import { globalLogger } from 'core/utils/GlobalLogger';
import { IGlobalState } from 'core/models/redux';
import { editProfilesToDashboardRequest, editTagsByObjectRequest } from 'redux-v2/global-store/global-store-requests';
import { ClickSource, PanelSourceTypes } from 'core/constants/dashboard';
import { IColumn } from 'core/models/report-response';
import { IReportConfig } from 'core/models/report-builder/report-builder';
import { IFilterDataLoadRequestPayload } from 'core/models/report-builder/report-builder-redux';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import SieraResponse from 'core/models/siera-response';
import { ITagsData, ObjectActionType, Types } from 'redux-v2/report-listing/report-listing.state';
import { ObjModel } from 'core/models';
import isEmpty from 'lodash/isEmpty';
import { isMeasure, isMeasureAndApplied } from 'core/utils/report.util';
import { getFiltersPayload, getLinkedFilters, parseAndGetColumnObj } from 'core/utils/dashboard.util';
import { getUserType } from 'redux-v2/auth-store/auth-store.util';
import { FeatureContext } from 'core/constants/common';
import { setSettingsError } from 'redux-v2/settings-store/settings-store.reducer';
import { getCreatedByListForDashboards } from 'redux-v2/customAnalytics/custom-analytics.requests';
import { getAddProfilesToDashboardPayload, getDashboardWidgetVisualization, getEditTagsPayload } from './dashboard.reducer.util';
import {
  getSelectedReportWidgetConfigFailure,
  getSelectedReportWidgetConfigRequest,
  getSelectedReportWidgetConfigSuccess,
  requestDashboardConfig,
  requestDashboardConfigFailure,
  requestDashboardConfigSuccess,
  requestPanelData,
  requestPanelDataFailure,
  requestPanelDataSuccess,
  updateDashboardBuilderPanelInfo,
  updateWidgetSetting,
  validateEmbedFailureAction,
  validateEmbedRequestAction,
  validateEmbedSuccessAction,
} from './dashboard-store.reducer';
import { DashboardAction } from './dashboard-store.state';

export const getDashboardListRequest = createAsyncThunk(DashboardAction.REQUEST_DASHBOARDS_LIST,
  async () => SingletonDashboardService.getDasbhboardServiceInstance()
    .getDashboardList());

export const saveTempViewRequest = createAsyncThunk(DashboardAction.SAVE_TEMP_VIEW_REQUEST,
  async (params:{payload:SaveTempViewCriteria, panelId:string}) => SingletonDashboardService.getDasbhboardServiceInstance()
    .saveTempView(params.payload));

export const saveTempDrilldownRequest = createAsyncThunk(DashboardAction.SAVE_TEMP_DRILLDOWN_REQUEST,
  async (params:{payload:SaveTempDrilldownCriteria, panelId:string}) => SingletonDashboardService.getDasbhboardServiceInstance()
    .saveTempDrilldownState(params.payload));

export const setPersonalizedDashboardRequest = createAsyncThunk(DashboardAction.SET_PERSONALIZED_DASHBOARD_REQUEST,
  async (criteria: PersonalizedDashboardCriteria) => SingletonDashboardService.getDasbhboardServiceInstance()
    .setPersonalizedDashboard(criteria));

export const dashboardAccessControlRequest = createAsyncThunk(DashboardAction.DASHBOARD_ACCESS_CONTROL_REQUEST,
  async (params:{dashboardId: string, type: AccessType}) => SingletonDashboardService.getDasbhboardServiceInstance()
    .accesscontrol(params.dashboardId, params.type));

export const deleteDashboardItemRequest = createAsyncThunk(DashboardAction.DELETE_DASHBOARD,
  async (dashboardID: string) => SingletonDashboardService.getDasbhboardServiceInstance()
    .deleteDashboard(dashboardID));

export const getFiltersListInfoRequest = createAsyncThunk(DashboardAction.GET_FILTERS_LIST_REQUEST,
  async (filteredReportIdsList: String[]) => SingletonDashboardService.getDasbhboardServiceInstance()
    .getFiltersList({ reportIDs: filteredReportIdsList }));

// Dashboard builder API calls

export const getAllReportsRequest = createAsyncThunk(DashboardAction.GET_REPORT_LIST_REQUEST,
  async (globalTags: ITagsData[]) => new DashboardBuilderService()
    .getAllReports());

export const getWidgetReportDataRequest = createAsyncThunk(DashboardAction.GET_WIDGET_REPORT_DATA_REQUEST,
  async (params:{criteria : PanelDataFetchCriteria, newPanelId:string}, thunkAPI) => {
    const panelData = await new DashboardBuilderService().getWidgetReportData(params.criteria);
    thunkAPI.dispatch(
      requestPanelDataSuccess({
        response: panelData,
        panelIds: [params.newPanelId],
      }),
    );
    return { panelData: panelData || [] };
  });

export const saveDashboardConfigurationRequest = createAsyncThunk<string, void, { rejectValue: SieraResponse<string>}>(DashboardAction.SAVE_DASHBOARD_CONFIG,
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { dashboard, globalStore } = getState() as IGlobalState;
    const {
      tags: { updatedTagsList, tagItemInfo, activeTagsList },
      profiles: { updatedProfilesList },
    } = globalStore;
    const savePayloadDashboardConfig = dashboard.dashboardConfig;
    const dashboardId = dashboard.dashboardConfig.Id;
    const isEditMode = dashboard?.isEditMode;
    let newDashboardId: SieraResponse<string> = { data: '' };
    try {
      newDashboardId = await new DashboardBuilderService().saveDashboardConfig(savePayloadDashboardConfig);
      globalLogger(`save success - new Dashboard ID: ${newDashboardId}`);
      dispatch(editTagsByObjectRequest(getEditTagsPayload(updatedTagsList, activeTagsList, {
        ...tagItemInfo, ObjectId: newDashboardId.data, Id: newDashboardId.data, ObjectType: ObjectActionType.Dashboard, Type: Types.Custom,
      })));
      if (!isEditMode || (isEditMode && !dashboardId)) {
        dispatch(
          editProfilesToDashboardRequest(
            getAddProfilesToDashboardPayload(updatedProfilesList, newDashboardId.data),
          ),
        );
      }

      return newDashboardId.data;
    } catch (err) {
      if ((err as Response)?.status === statusCodes.Forbidden) {
        dispatch(getCreatedByListForDashboards(dashboardId));
      }
      if ((err as Response)?.status === statusCodes.Unprocessable) dispatch(setSettingsError(true));
      return rejectWithValue({
        status: (err as Response)?.status || 500,
        data: (err as Error).message || 'Something went wrong',
      });
    }
  });

export const batchHelperForDataRequest = (
  res: IPanelDataResponse,
  target: Array<string> = [],
  isFailed: boolean = false,
  status?:statusCodes,
) => {
  if (isFailed) {
    store.dispatch(requestPanelDataFailure({ target, status }));
  } else {
    store.dispatch(
      requestPanelDataSuccess({
        response: res,
        panelIds: target,
        panelStatusCode: res?.statusCodes,
      }),
    );
  }
};

export const resetDataRequestArray = () => {
  SingletonDashboardService.getDasbhboardServiceInstance().resetDataRequestArray();
};

export const getFiltersListRequest = (panelList: IPanel[]) => async (dispatch: Function) => {
  const reportIdList = (isValidArrayAndNotEmpty(panelList) && panelList?.map((item) => item.Source.SourceId)) || [];
  const filteredReportIdsList = Array.from(new Set(reportIdList)); // removing duplicate reportIds
  dispatch(getFiltersListInfoRequest(filteredReportIdsList));
};
export const getSelectedReportWidgetDetails = (reportId: string, featureContext: FeatureContext) => async (dispatch: any) => {
  dispatch(getSelectedReportWidgetConfigRequest()); // setting processing flag
  const dashboardService = new DashboardBuilderService();

  const criteria = {
    SourceType: PanelSourceTypes.Report,
    SourceId: reportId,
    Filters: {},
    PageSize: 10,
    Url: '',
    FeatureContext: featureContext,
  };

  // set currently selected report ID
  dispatch(updateWidgetSetting({ SelectedReportId: reportId }));
  // updating the widget settings inside selected report obj

  // trigger both config and data requests
  Promise.all([
    dashboardService.getReportConfig(reportId),
    dashboardService.getWidgetReportData(criteria),
  ])
    .then((response) => {
      const reportConfig: IReportConfig = response[0];
      const reportData: IPanelDataResponse = response[1];

      const widgetSettings: IWidgetSettings = {
        Type: reportConfig?.Visualization?.Type,
        Title: reportConfig?.Details?.Title,
        PageSize: reportConfig?.Paging?.PageSize,
        attributes: getDashboardWidgetVisualization(reportConfig?.Visualization?.Builder?.Properties || reportConfig?.Visualization?.Builder?.YAxis?.Properties),
        Columns: reportData?.Visualization.Columns?.map((column: IColumn) => ({
          Id: column.Id,
          Name: column.Name,
        })),
        SelectedMeasures: reportData?.Visualization.Columns?.map(
          (column: IColumn) => ({
            Id: column?.Id,
            Name: column?.Name,
          }),
        ),
      };
      const widgetDetails: IReportWidget = {
        rawReportConfig: reportConfig,
        reportData,
        widgetSettings: { ...widgetSettings },
      };
      dispatch(getSelectedReportWidgetConfigSuccess(widgetDetails));
    })
    .catch(() => dispatch(getSelectedReportWidgetConfigFailure()));
};
export const getSelectedReportWidgetDetailsForEdit = (reportId: string, updatedCriteria: PanelDataFetchCriteria, updatedWidgetInfo: IWidgetSettings) => async (dispatch: any) => {
  dispatch(getSelectedReportWidgetConfigRequest());
  const dashboardService = new DashboardBuilderService();

  // set currently selected report ID
  dispatch(updateWidgetSetting({ SelectedReportId: reportId }));

  // trigger both config and data requests
  Promise.all([
    dashboardService.getReportConfig(reportId),
    dashboardService.getWidgetReportData(updatedCriteria),
  ])
    .then((response) => {
      const reportConfig: IReportConfig = response[0];
      const reportData: IPanelDataResponse = response[1];

      const measuresList = isValidArrayAndNotEmpty(reportData?.Visualization?.Columns) ? reportData.Visualization.Columns
        .filter((m) => isMeasureAndApplied(m)) : [];

      const selectedMeasures = isValidArrayAndNotEmpty(updatedWidgetInfo?.SelectedMeasures)
        ? updatedWidgetInfo.SelectedMeasures.filter((measure) => parseAndGetColumnObj(measuresList, measure)) : [];

      const widgetSettings: IWidgetSettings = {
        ...updatedWidgetInfo,
        Columns: isValidArrayAndNotEmpty(reportConfig?.Columns) ? reportConfig?.Columns?.map((column: IColumn) => ({
          Id: column?.Id,
          Name: column?.Name,
        })) : [],
        SelectedMeasures: selectedMeasures,
      };

      const widgetDetails: IReportWidget = {
        rawReportConfig: reportConfig,
        reportData,
        widgetSettings,
      };
      dispatch(getSelectedReportWidgetConfigSuccess(widgetDetails));
    })
    .catch(() => dispatch(getSelectedReportWidgetConfigFailure()));
};
export const getDashboardConfig = (dashboardId: string, personalizeHome: boolean) => async (dispatch: Function, getState: Function) => {
  const { dashboard } = getState();
  dispatch(requestDashboardConfig());
  SingletonDashboardService.getDasbhboardServiceInstance()
    .getDashboardConfig(dashboardId, personalizeHome)
    .then((res: SieraResponse<IDashboardConfig>) => {
      dispatch(requestDashboardConfigSuccess(res));
      if (dashboard?.isEditMode) {
        dispatch(getFiltersListRequest(res?.data?.Panels));
      }
    })
    .catch(() => dispatch(requestDashboardConfigFailure()));
};
export const validateEmbedUrl = (
  formValue: { name: string; url: string },
  isValidMailMergeURL: boolean,
  source: ClickSource,
) => async (dispatch: Function) => {
  const { name, url } = formValue;
  let updatedUrl = url.trim();
  if (
    updatedUrl
      && !updatedUrl.startsWith('http://')
      && !updatedUrl.startsWith('https://')
  ) {
    updatedUrl = `https://${updatedUrl}`;
  }
  dispatch(validateEmbedRequestAction());
  const dashboardService = new DashboardBuilderService();
  dashboardService
    .validateEmbedURL(updatedUrl)
    .then(async (res) => {
      let embedURl;
      if (res === 'Invalid Domain') {
        dispatch(validateEmbedFailureAction({ urlError: true, mailMergeError: false }));
        return '';
      }
      if (res === 'Mail Merge Not Allowed') {
        dispatch(validateEmbedFailureAction({ urlError: false, mailMergeError: true }));
        return '';
      }
      if (isValidMailMergeURL) {
        embedURl = await dashboardService.replaceMailMergeFields(updatedUrl);
      } else {
        embedURl = updatedUrl;
      }
      return embedURl;
    })
    .then((embedUrl) => {
      if (embedUrl) {
        dispatch(
          validateEmbedSuccessAction({
            name,
            sourceUrl: updatedUrl,
            embedUrl,
            source,
          }),
        );
      }
    })
    .catch(() => {
      dispatch(validateEmbedFailureAction({ urlError: false, mailMergeError: false }));
    });
};

export const getPanelData = (source: PanelDataFetchCriteria, Target: Array<string>, FiltersMetadata?: ObjModel.Obj) => (dispatch: Function) => {
  dispatch(requestPanelData(Target));

  let sourceWithTarget: PanelDataFetchCriteria = { ...source, Target };
  if (!isEmpty(FiltersMetadata)) {
    sourceWithTarget = {
      ...sourceWithTarget,
      FiltersMetadata: { ...FiltersMetadata },
    };
  }
  SingletonDashboardService.getDasbhboardServiceInstance().getPanelData(
    sourceWithTarget,
  );
};

export const addSelectedReportConfig = () => async (dispatch: any, getState: Function) => {
  const selectedReportWidget: IReportWidget = getState().dashboard.dashboardBuilder.selectedReportWidget;
  // in dashboard creation mode, we will be adding the panel ids for each panel, to get unique id we are using uuidv4()
  const newPanelId = uuidv4();
  const dataColumns = selectedReportWidget.reportData.Visualization.Columns;
  const dimensions = dataColumns
    .filter((col) => !isMeasure(col))
    .map((item) => ({
      Id: item.Id,
      Name: item.Name,
    }));
  const measures = selectedReportWidget.widgetSettings.SelectedMeasures;
  const columns = [...dimensions, ...measures];

  const updatedWidgetSettings: IWidgetSettings = {
    ...selectedReportWidget.widgetSettings,
    uniquePanelId: newPanelId, // adding unique id to the widget settings
    Columns: columns,
  };

  let updatedFeatureContext = null;
  if (Object.prototype.hasOwnProperty.call(selectedReportWidget.rawReportConfig, 'FeatureContext') && !selectedReportWidget.rawReportConfig?.FeatureContext) {
    updatedFeatureContext = FeatureContext.Sales;
  } else if (selectedReportWidget.rawReportConfig?.FeatureContext) {
    updatedFeatureContext = selectedReportWidget.rawReportConfig?.FeatureContext;
  }
  const updatedReportConfig = {
    ...selectedReportWidget.rawReportConfig,
    FeatureContext: updatedFeatureContext,
  };

  // adding config details to dashboard config
  dispatch(
    updateDashboardBuilderPanelInfo({
      reportConfig: updatedReportConfig,
      widgetSettings: updatedWidgetSettings,
      isPanelEdit: false,
    }),
  );
  const criteria = {
    SourceType: PanelSourceTypes.Report,
    SourceId: selectedReportWidget.rawReportConfig.ReportConfigId,
    Filters: {},
    PageSize: selectedReportWidget.widgetSettings.PageSize, // actual page size which user selected
    Url: '',
    FeatureContext: selectedReportWidget.rawReportConfig.FeatureContext || FeatureContext.Sales,
  };
  dispatch(getWidgetReportDataRequest({ criteria, newPanelId }));
};
export const updateSelectedReportConfig = () => async (dispatch: any, getState: Function) => {
  const { dashboard } = getState() as IGlobalState;
  const { staticGlobalFilters } = dashboard?.filterStore;
  const { selectedReportWidget } = dashboard?.dashboardBuilder;
  const { Panels } = dashboard?.dashboardConfig;
  const updatedPanelId = selectedReportWidget?.widgetSettings?.uniquePanelId;
  const dataColumns = selectedReportWidget.reportData.Visualization.Columns;
  const dimensions = isValidArrayAndNotEmpty(dataColumns) ? dataColumns
    .filter((col) => !isMeasure(col))
    .map((item) => ({
      Id: item.Id,
      Name: item.Name,
    })) : [];
  const measures = selectedReportWidget.widgetSettings.SelectedMeasures;
  const columns = [...dimensions, ...measures];

  const updatedWidgetSettings: IWidgetSettings = {
    ...selectedReportWidget.widgetSettings,
    Columns: columns,
  };
  const globalFiltersValue = getFiltersPayload(staticGlobalFilters);
  const panelConfig = Panels.find((panel:IPanel) => panel.Id === updatedPanelId);
  const criteria = {
    SourceType: PanelSourceTypes.Report,
    SourceId: panelConfig?.Source?.SourceId,
    Filters: getLinkedFilters(panelConfig?.Source?.GlobalFiltersMapping, globalFiltersValue),
    PageSize: selectedReportWidget.widgetSettings.PageSize,
    Url: '',
    FeatureContext: selectedReportWidget.rawReportConfig.FeatureContext || FeatureContext.Sales,
  };
  // adding config details to dashboard config
  dispatch(
    updateDashboardBuilderPanelInfo({
      reportConfig: selectedReportWidget.rawReportConfig,
      widgetSettings: updatedWidgetSettings,
      isPanelEdit: true,
    }),
  );

  dispatch(getWidgetReportDataRequest({ criteria, newPanelId: updatedPanelId }));
};

export const filterLoadRequest = createAsyncThunk(DashboardAction.FILTER_DATA_LOAD_REQUEST,
  async (params: IFilterDataLoadRequestPayload, { getState }) => {
    const { dashboard, auth: { userAttributes } } = getState() as IGlobalState;
    const userTypeInfo = getUserType(userAttributes?.AccessibilityMap);
    const featureContext = userTypeInfo === FeatureContext.Hybrid ? FeatureContext.Sales : userTypeInfo;
    const { dynamicGlobalFilters } = dashboard?.filterStore;

    const {
      filterId, filterValue, isLazyLoad, variables,
    } = params;
    const filter: IFilterItem = dynamicGlobalFilters.find(
      (item: IFilterItem) => item.ID === filterId,
    );
    const linkedFilter: IFilterItem = dynamicGlobalFilters.find((filterItem: IFilterItem) => filterItem.ID === filter.filterLinkedKey);
    const updatedFilterData = filter.filterLinkedKey
      ? linkedFilter?.filterData?.FilterResponse
      : null;

    return new FilterFetchUtility(variables)
      .GetSingleFilterData(
        filter,
        updatedFilterData,
        isLazyLoad ? filterValue : filter.filterData.FilterResponse,
        filter.filterData,
        {},
        featureContext,
      );
  });
