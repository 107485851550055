import React from 'react';

type Props = {
    width: number;
    height: number;
    fill?: string;
}

const NewReplaceIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <path d="M11.4833 8.38333L10.7833 7.68333L12.7666 5.7H1.94992V4.7H12.7666L10.7666 2.7L11.4666 2L14.6666 5.2L11.4833 8.38333ZM4.51659 14L1.33325 10.8167L4.53325 7.61667L5.23325 8.31667L3.23325 10.3167H14.0499V11.3167H3.23325L5.21659 13.3L4.51659 14Z" fill={fill} />
  </svg>

);

NewReplaceIcon.defaultProps = {
  fill: 'var(--secondaryColor)',
};

export default NewReplaceIcon;
