/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IBuilderMeasure,
  IDetails, IPaging, IReportConfig, ISetDimensionsBinningPayload, ISetGroupingPayload, ITemplateList, ReportBuilderStatus,
} from 'core/models/report-builder/report-builder';
import { APIRequestStatus } from 'core/constants/redux';
import {
  DynamicMeasureResponse, IColumn, IField, IFilterConfig,
} from 'core/models/report-response';
import {
  buildCustomMeasure, buildMeasureColumn, buildCustomDimension,
} from 'core/utils/report-expressions';
import {
  ColumnModes, DimensionMode, DimensionStatus, FieldEntitiesType, dynamicMeasureDefaultConfig,
  statusCodes,
} from 'core/constants/report';

import SieraResponse from 'core/models/siera-response';
import { ObjModel, ReportResponseModel } from 'core/models';
import {
  IAddDynamicFilterPayload, IDeleteDynamicFilterPayload, IFilterFormValues,
  IFilterValueChangePayload, ILoadMoreFilterPayloadRequest, ISearchFilterPayloadRequest,
  ISwitchUserFieldFilterPayload,
} from 'core/models/report-builder/report-builder-redux';
import { FilterType } from 'core/constants/filter';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import isEmpty from 'lodash/isEmpty';
import { IUpdateVisualizationPayload } from 'redux-v2/report/report-store.state';
import { IFilterResponse } from 'core/models/filter';
import { IVariables } from 'core/models/store';
import { IPanelDataResponse } from 'core/models/dashboard';
import {
  getDefaultEntity,
  getUniqIdentiferForDrilldownCols, isDrilldownPossible, setDrilldownEnableFlag, sortColumns,
} from 'core/utils/report.util';
import { getFilterConfigFromTemplate, getMappedMetaFields } from 'core/utils/common.utility';
import { isValidArrayAndNotEmpty, isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { cloneDeep, fromPairs, isString } from 'lodash';
import { defaultPageValue } from 'core/constants/pagination';
import { IDimension } from 'core/models/report-redux';
import { getEntityJoinIdForCustomMeasure } from 'components/feature/ReportBuilderV2/ReportBuilderDetail/DrilldownTabContent/drilldown.util';
import initialReportBuilderState from './report-builder-store.initial-state';
import {
  getAdditionalDrilldownColumnsRequest,
  getDrilldownConfig,
  getDefaultDrilldownColumnsRequest,
  saveDrilldownColumnConfig,
  getDynamicDimensions, getReportBuilderDynamicFilters, getDynamicMeasures,
  getFieldsRequest, getReportRawConfig, getReportRenderConfig, getReportTemplateList,
  saveReportConfig, getReportBuilderFiltersLoad,
  getReportBuilderDynamicUserFilters,
  getReportBuilderDynamicUserDimensions,
  getDataSourcesList,
} from './report-builder-store.requests';
import {
  DrilldownConfigMapping,
  DrilldownFieldUpdationsAction,
  ExpressionContext,
  IAddMeasurePayload,
  ICustomExpression,
  IDataSourceApiResponse,
  IMoveMeasurePayload,
  IRemoveMeasurePayload,
  IReportBuilderState, ISetMeasurePayload, ISortColumnPayload, ISortDrilldownColumnPayload, MeasureListWithJoinId,
  ReportBuilderActions, ReportBuilderSaveStatus,
  IResetReportConfigPayload,
} from './report-builder-store.state';
import {
  getAppliedDimensions, getDrilldownMapping, getInitialDrilldownConfigInfo,
  getStateOnMoveColumns,
  getStateOnUpdateDrilldownColumn, getUpdatedDrilldownColConfig,
  sortDrilldownColumns,
  groupByJoinIdAndEntity,
  getUpdatedFetchdrilldownColumnsRes,
  handleCustExpUpdateForDrilldownMapper,
  getAppliedDimensionsOnSuccess, getAppliedFilterFromTemplate, getCombinedReportConfig, getExpressionStoreFromConfig,
  getIsFilterFormDisable, getIsReportBuilderSaveFormDisable, getMeasureColumns, getStateOnAddDynamicFilter, getStateOnAddMeasure,
  getStateOnBinningChange, getStateOnMoveMeasure, getStateOnRemoveMeasure, getStateOnSetGrouping, getStateOnSwitchUserFilter, getStateOnUpdateMeasure,
  getUpdatedPayloadAfterInitialSave, getUpdatedReportFilterConfig, reorderAppliedDimensions, resetFilterOnFailure, selectedReportTemplate,
  getUpdatedDataSourceResponse,
  convertDrilldownMappingIntoIdMapping,
} from './report-builder-store.utils';
import { fieldSelectors } from './report-builder-store.selectors';
import { IGlobalState } from '../../core/models/redux';

const reportBuilderSlice = createSlice({
  name: 'ReportBuilderState',
  initialState: initialReportBuilderState,
  reducers: {
    [ReportBuilderActions.SEARCH_FILTER_DATA_LOAD_REQUEST]: (state: IReportBuilderState, action:PayloadAction<ISearchFilterPayloadRequest>) => {
      const { filterId, searchKey } = action.payload;
      const filter = state.filterBuilder.filterConfig.find(
        (config) => config.ID === filterId,
      );
      const newAppliedFilters = state.filterBuilder.appliedFilters;
      if (filter) {
        newAppliedFilters[filterId].OptionsMeta.SearchKey = searchKey;
        newAppliedFilters[filterId].OptionsMeta.Page.PageIndex = 0;
        newAppliedFilters[filterId].FilterOptions = [];
      }
      state.filterBuilder.appliedFilters = newAppliedFilters;
    },
    [ReportBuilderActions.LOAD_MORE_FILTER_DATA_LOAD_REQUEST]: (state: IReportBuilderState, action:PayloadAction<ILoadMoreFilterPayloadRequest>) => {
      const { filterId } = action.payload;
      const filter = state.filterBuilder.filterConfig.find(
        (config) => config.ID === filterId,
      );
      const newAppliedFilters = state.filterBuilder.appliedFilters;

      if (filter) {
        newAppliedFilters[filterId].OptionsMeta.Page.PageIndex += 1;
      }
      state.filterBuilder.appliedFilters = newAppliedFilters;
      const { Title, Description } = state.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
        || getIsFilterFormDisable(state.filterBuilder);
    },
    [ReportBuilderActions.ADD_DYNAMIC_FILTER]: (state: IReportBuilderState, action: PayloadAction<IAddDynamicFilterPayload>) => getStateOnAddDynamicFilter(state, action.payload.index),
    [ReportBuilderActions.DELETE_DYNAMIC_FILTER]: (state: IReportBuilderState, action: PayloadAction<IDeleteDynamicFilterPayload>) => {
      const { filterId } = action.payload;
      const newFilterConfig = state.filterBuilder.filterConfig;
      const newAppliedFilters = state.filterBuilder.appliedFilters;
      const index = newFilterConfig.findIndex(
        (config) => config.ID === filterId,
      );
      const userMultiSelectFilterData = newFilterConfig.find(
        (config) => config.Type === FilterType.UserMultiSelect,
      );
      if (index !== -1) {
        newFilterConfig[index].IsRemoved = true;
        if (
          newFilterConfig[index]?.Entity === FieldEntitiesType.User
          && userMultiSelectFilterData
        ) {
          state.filterBuilder.isUsersModified = true;
          newAppliedFilters[userMultiSelectFilterData.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(
            userMultiSelectFilterData,
            null,
          );
        }
      }
      delete newAppliedFilters[filterId];
      state.filterBuilder.appliedFilters = newAppliedFilters;
      state.filterBuilder.filterConfig = newFilterConfig;
      const { Title, Description } = state.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
        || getIsFilterFormDisable(state.filterBuilder);
    },
    [ReportBuilderActions.FILTER_VALUE_CHANGE]: (state: IReportBuilderState, action: PayloadAction<IFilterValueChangePayload>) => {
      const { filterId, filterValue, variables } = action.payload;
      const fetchFilter = new FilterFetchUtility(variables);
      const newAppliedFilters = state.filterBuilder.appliedFilters;

      const selectedFilterConfig = state.filterBuilder.filterConfig.find(
        (item: IFilterConfig) => item.ID === filterId,
      );
      if (!isEmpty(state.filterBuilder.filterConfig)) {
        state.filterBuilder.filterConfig.forEach((item) => {
          if (item.ID === filterId && newAppliedFilters[item.ID]) {
            newAppliedFilters[filterId].FilterResponse = filterValue;
          } else if (
            (item.LinkedTo && item.LinkedTo === filterId)
            || (selectedFilterConfig?.Entity === FieldEntitiesType.User
              && item.Type === FilterType.UserMultiSelect)
          ) {
            newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(
              item,
              null,
            );
            state.filterBuilder.isUsersModified = true;
          }
          if (item.Type === FilterType.UserMultiSelect && item?.Metadata?.Status) {
            newAppliedFilters[item.ID] = {
              ...newAppliedFilters[item.ID],
              userStatus: item?.Metadata?.Status,
            };
          }
        });
      }
      state.filterBuilder.appliedFilters = newAppliedFilters;
      const { Title, Description } = state.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
        || getIsFilterFormDisable(state.filterBuilder);
    },
    [ReportBuilderActions.UPDATE_FILTER_FORM]: (state:IReportBuilderState, action: PayloadAction<IFilterFormValues>) => {
      const { name, filterId, userStatus } = action.payload;
      const newFilterConfig = state.filterBuilder.filterConfig;
      const newAppliedFilter = state.filterBuilder.appliedFilters;
      const statusChanged = newAppliedFilter?.[filterId]?.userStatus !== userStatus;
      newAppliedFilter[filterId] = {
        ...newAppliedFilter[filterId],
        label: name,
        ...(userStatus ? { userStatus } : {}),
        ...(statusChanged ? {
          FilterResponse: {
            ...newAppliedFilter[filterId]?.FilterResponse,
            Type: 'ALL',
            UserIds: [],
          },
        } : {}),
      };
      const index = newFilterConfig.findIndex(
        (config) => config.ID === filterId,
      );
      newFilterConfig[index].Label = name;
      if (userStatus) {
        newFilterConfig[index].Metadata.Status = userStatus;
      }
      state.filterBuilder.filterConfig = newFilterConfig;
      state.filterBuilder.appliedFilters = newAppliedFilter;
    },
    [ReportBuilderActions.UPDATED_SELECTED_TEMPLATE]: (state: IReportBuilderState, action: PayloadAction<ITemplateList>) => {
      if (!action.payload?.ReportConfig) return;
      const measureColumns = getMeasureColumns(action.payload.ReportConfig, true);
      const reportConfig = selectedReportTemplate(action.payload, action.payload.FilterMetaInfo);
      state.updatedReportBuilderInfo.selectedTemplate = action.payload;
      state.updatedReportBuilderInfo.selectedTemplate.ReportConfig = reportConfig;
      state.updatedReportBuilderInfo.reportBuilderSavePayload = reportConfig;
      state.updatedReportBuilderInfo.appliedDimensions = getAppliedDimensions(state, reportConfig, action.payload.FilterMetaInfo);
      state.updatedReportBuilderInfo.appliedMeasures = measureColumns?.map((measure: IColumn) => setDrilldownEnableFlag(measure)) || [];

      state.updatedReportBuilderInfo.sortState = [...(action.payload.ReportConfig.Sorting ?? [])];
      state.updatedReportBuilderInfo.calculatedColumns = new Set<string>((reportConfig.CalculatedColumns ?? []).map((c) => c.Alias));
      state.filterBuilder.filterMetaInfo = action.payload.FilterMetaInfo;
      state.assortedInfo.isFormDisabled = true;
      state.assortedInfo.settings = action.payload.Settings;
    },
    [ReportBuilderActions.UPDATE_SELECTED_TAB]: (state: IReportBuilderState, action: PayloadAction<string>) => {
      state.assortedInfo.selectedTabInfo = action.payload;
    },
    [ReportBuilderActions.UPDATE_REPORT_TEMPLATE_DETAIL]: (state: IReportBuilderState, action: PayloadAction<IDetails>) => {
      const { Title, Description } = action.payload;
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
            || getIsFilterFormDisable(state.filterBuilder);
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Details = action.payload;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Visualization.DisplayName = Title;
    },
    [ReportBuilderActions.UPDATE_VISUALIZATION]: (state: IReportBuilderState, action: PayloadAction<IUpdateVisualizationPayload>) => {
      const { type, attributes } = action.payload;
      const { Title, Description } = state.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Visualization.Type = type;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Visualization.Builder = {
        ...state.updatedReportBuilderInfo.reportBuilderSavePayload
          .Visualization.Builder,
        Properties: { ...attributes },
      };
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
            || getIsFilterFormDisable(state.filterBuilder);
    },
    [ReportBuilderActions.UPDATE_PAGINATION]: (state: IReportBuilderState, action: PayloadAction<IPaging>) => {
      const { PageIndex, PageSize } = action.payload;
      const { Title, Description } = state.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Paging.PageIndex = PageIndex;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Paging.PageSize = PageSize;
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
            || getIsFilterFormDisable(state.filterBuilder);
    },
    [ReportBuilderActions.RESET_REPORT_TEMPLATE]: (state:IReportBuilderState) => {
      const updatedInitialState = {
        ...initialReportBuilderState,
        assortedInfo: {
          ...initialReportBuilderState.assortedInfo,
          reportBuilderSaveStatus: state.assortedInfo.reportBuilderSaveStatus, // we want to keep the updated status for enabling toast notification on redirection
        },
      };
      return { ...updatedInitialState };
    },
    [ReportBuilderActions.SET_DIMENSIONS_GROUPING]: (state:IReportBuilderState, action: PayloadAction<ISetGroupingPayload>) => getStateOnSetGrouping(state, action.payload),
    [ReportBuilderActions.SET_DIMENSIONS_BINNING]: (state:IReportBuilderState, action: PayloadAction<ISetDimensionsBinningPayload>) => getStateOnBinningChange(state, action.payload),
    [ReportBuilderActions.ADD_MEASURE]: (state:IReportBuilderState, action: PayloadAction<IAddMeasurePayload>) => getStateOnAddMeasure(state, action.payload),
    [ReportBuilderActions.REM_MEASURE]: (state:IReportBuilderState, action: PayloadAction<IRemoveMeasurePayload>) => getStateOnRemoveMeasure(state, action.payload),
    [ReportBuilderActions.MOV_MEASURE]: (state:IReportBuilderState, action: PayloadAction<IMoveMeasurePayload>) => getStateOnMoveMeasure(state, action.payload),
    [ReportBuilderActions.SET_MEASURE]: (state:IReportBuilderState, action: PayloadAction<ISetMeasurePayload>) => getStateOnUpdateMeasure(state, action.payload),
    [ReportBuilderActions.UPDATE_SAVE_REPORT_BUILDER_STATUS]: (state: IReportBuilderState, action: PayloadAction<ReportBuilderSaveStatus>) => {
      state.assortedInfo.reportBuilderSaveStatus = action.payload;
    },
    [ReportBuilderActions.SORT_COLUMN]: (state: IReportBuilderState, action: PayloadAction<ISortColumnPayload>) => {
      const { updatedReportBuilderInfo: reportBuilder } = state;
      const [sortState, hasChanged] = sortColumns(
        reportBuilder.sortState,
        reportBuilder.appliedDimensions,
        reportBuilder.appliedMeasures,
        action.payload.columnType,
        action.payload.columnName,
        action.payload.order,
        action.payload.multiSort,
        action.payload.Id,
      );
      if (!hasChanged) return;
      state.updatedReportBuilderInfo.sortState = sortState;
    },
    [ReportBuilderActions.ADD_OR_UPDATE_CUSTOM_EXPRESSION]: (state: IReportBuilderState, action: PayloadAction<ICustomExpression>) => {
      const customExp = action.payload;
      const uniqueId = customExp.uniqueId;
      const newMeasure = buildCustomMeasure(customExp.displayName, customExp.Mode, customExp.jsonExpression, uniqueId, customExp?.Formatter, customExp.DataType);
      const expressionType = action.payload.ExpressionType;
      const updatedAppliedMeasures = state.updatedReportBuilderInfo.appliedMeasures;
      let updatedAppliedDimensions = state.updatedReportBuilderInfo.appliedDimensions;
      const rowGroupingDimensions = state.updatedReportBuilderInfo.appliedDimensions.filter(
        (dim) => dim?.DimensionMode !== DimensionMode.ColumnGroup
      && dim?.Applied !== DimensionStatus.NotApplied,
      );

      const destIndex = rowGroupingDimensions.length;

      let newExpression: IColumn| IDimension;
      let editMode = false;
      if (state?.updatedReportBuilderInfo?.expressionStore && state.updatedReportBuilderInfo.expressionStore[uniqueId]) {
        editMode = true;
      }

      switch (expressionType) {
        case ExpressionContext.Measure: {
          const defaultEntity = getDefaultEntity(state?.updatedReportBuilderInfo?.selectedTemplate?.ReportConfig);
          const fieldsData = fieldSelectors({ reportBuilder: state } as IGlobalState);
          const editedCustIndex = updatedAppliedMeasures.findIndex((mes) => mes.BuilderConfig?.uniqueKey === uniqueId);
          const originalCustomMeasure = updatedAppliedMeasures[editedCustIndex];
          newExpression = buildCustomMeasure(customExp.displayName, customExp.Mode, customExp.jsonExpression, uniqueId, customExp?.Formatter, customExp.DataType);
          if (editMode) {
            if (customExp.Mode === ColumnModes.Sortable) {
              updatedAppliedMeasures[editedCustIndex] = newExpression as IColumn;
            }

            if (originalCustomMeasure.Props.DrilldownEnabled) {
              const { newConfigMapping, newConfigColumns } = handleCustExpUpdateForDrilldownMapper(originalCustomMeasure, newExpression, state.updatedReportBuilderInfo.drilldownConfigInfo, defaultEntity, fieldsData);
              state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = newConfigMapping;
              state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = newConfigColumns;
            }
          } else {
            updatedAppliedMeasures.push(newExpression as IColumn);
            if (newExpression.Props?.DrilldownEnabled) {
              state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = groupByJoinIdAndEntity(
                state?.reportBuilderInfo.fields,
                newMeasure,
                state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping,
                state?.updatedReportBuilderInfo?.appliedMeasures,
                defaultEntity,
              );
            }
          }

          break;
        }
        case ExpressionContext.Dimension:
          newExpression = buildCustomDimension(customExp.displayName, customExp.Mode, customExp.jsonExpression, uniqueId, customExp.DataType, customExp?.dimensionType);
          if (editMode) {
            const editedCustIndex = updatedAppliedDimensions.findIndex((dim) => dim.BuilderConfig?.uniqueKey === uniqueId);
            updatedAppliedDimensions[editedCustIndex] = {
              ...updatedAppliedDimensions[editedCustIndex],
              Expression: newExpression?.Expression,
              BuilderConfig: { ...newExpression?.BuilderConfig },
              Name: newExpression.Name,
            };
            updatedAppliedDimensions = reorderAppliedDimensions(updatedAppliedDimensions);
          } else {
            state.updatedReportBuilderInfo.expressionStore[uniqueId] = customExp;
            updatedAppliedDimensions.splice(destIndex, 0, newExpression);
          }

          break;
        default:
      }

      state.updatedReportBuilderInfo.appliedMeasures = updatedAppliedMeasures;
      state.updatedReportBuilderInfo.appliedDimensions = updatedAppliedDimensions;
      state.updatedReportBuilderInfo.expressionStore[uniqueId] = customExp;
    },
    [ReportBuilderActions.DELETE_CUSTOM_EXPRESSION]: (state: IReportBuilderState, action: PayloadAction<string | number>) => {
      const uniqueId = action.payload;

      let updatedAdditionalMeasures = state.reportBuilderInfo.additionalMeasures;
      updatedAdditionalMeasures = isValidArrayAndNotEmpty(updatedAdditionalMeasures) && updatedAdditionalMeasures.filter((measure:IColumn) => measure.BuilderConfig?.uniqueKey !== uniqueId);
      const newExpressionStore = { ...state?.updatedReportBuilderInfo?.expressionStore };
      delete newExpressionStore[uniqueId];
      state.reportBuilderInfo.additionalMeasures = updatedAdditionalMeasures;
      state.updatedReportBuilderInfo.expressionStore = newExpressionStore;
    },
    [ReportBuilderActions.COMBINE_RAW_AND_RENDER_CONFIG]: (state: IReportBuilderState) => {
      const updatedConfig = getCombinedReportConfig(state.reportEditInfo.rawReportConfig, state.reportEditInfo.renderReportConfig);
      state.updatedReportBuilderInfo.selectedTemplate.ReportConfig = updatedConfig;
      state.updatedReportBuilderInfo.sortState = state.reportEditInfo.rawReportConfig.Sorting ?? [];
      state.updatedReportBuilderInfo.appliedDimensions = getAppliedDimensions(state, updatedConfig, state?.reportEditInfo?.renderReportConfig?.Filters);
      const appliedMeasures = getMeasureColumns(updatedConfig, true);
      state.updatedReportBuilderInfo.appliedMeasures = appliedMeasures?.map((measure: IColumn) => setDrilldownEnableFlag(measure)) || [];
      state.updatedReportBuilderInfo.reportBuilderSavePayload = { ...state.reportEditInfo.rawReportConfig, FiltersMetadata: state?.reportEditInfo?.renderReportConfig?.FiltersMetadata };
      state.updatedReportBuilderInfo.calculatedColumns = new Set<string>((updatedConfig?.CalculatedColumns ?? []).map((column) => column.Alias));
      state.updatedReportBuilderInfo.expressionStore = getExpressionStoreFromConfig(updatedConfig, state?.updatedReportBuilderInfo?.expressionStore);
      state.reportEditInfo.combineConfigLoaded = true;
      state.assortedInfo.isFormDisabled = false;
    },
    [ReportBuilderActions.SET_REPORT_EDIT_ID]: (state:IReportBuilderState, action:PayloadAction<string>) => {
      state.assortedInfo.reportId = action.payload;
      state.reportEditInfo.isEditing = true;
    },
    [ReportBuilderActions.SET_TEMPORARY_REPORT_ID]: (state:IReportBuilderState, action:PayloadAction<string>) => {
      state.reportEditInfo.temporaryReportId = action.payload;
    },
    [ReportBuilderActions.INITIAL_SAVE_TEMPLATE]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.INITIAL_SAVE_TEMPLATE] = APIRequestStatus.Processing;
    },
    [ReportBuilderActions.SET_INITIAL_SUCCESS_STATE]: (state:IReportBuilderState, action: PayloadAction<{reportId:string, variables: IVariables}>) => {
      state.requestProcessing[ReportBuilderActions.INITIAL_SAVE_TEMPLATE] = APIRequestStatus.Success;
      state.updatedReportBuilderInfo.selectedTemplate.ReportConfig = getUpdatedPayloadAfterInitialSave(
        state,
        action.payload.reportId,
      );
      state.updatedReportBuilderInfo.reportBuilderSavePayload = getUpdatedPayloadAfterInitialSave(
        state,
        action.payload.reportId,
      );
      state.previewBackUpInfo.filterBuilder = state.filterBuilder;
      state.previewBackUpInfo.updatedReportBuilderInfo = state.updatedReportBuilderInfo;
      state.assortedInfo.reportId = action.payload.reportId;
      state.filterBuilder.filterConfig = getFilterConfigFromTemplate(state.updatedReportBuilderInfo.reportBuilderSavePayload, state.filterBuilder.filterMetaInfo);
      state.filterBuilder.appliedFilters = getAppliedFilterFromTemplate(state, action.payload.variables);
    },
    [ReportBuilderActions.SET_INITIAL_FAILURE_STATE]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.INITIAL_SAVE_TEMPLATE] = APIRequestStatus.Failure;
    },
    [ReportBuilderActions.GET_PREVIEW_DATA]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_PREVIEW_DATA] = APIRequestStatus.Processing;
    },
    [ReportBuilderActions.SET_PREVIEW_DATA_SUCCESS_STATE]: (state: IReportBuilderState, action: PayloadAction<{ res: IPanelDataResponse, isInitialLoad?: boolean }>) => {
      state.previewData.data = action.payload.res;
      state.previewData.mappedMetaFields = getMappedMetaFields(
        action.payload.res.Visualization.Columns,
        action.payload.res?.Raw?.Data,
      );
      if (action.payload?.isInitialLoad) {
        state.updatedReportBuilderInfo.appliedMeasures = isValidArrayAndNotEmpty(state?.updatedReportBuilderInfo?.appliedMeasures) ? state.updatedReportBuilderInfo.appliedMeasures.map((measure: IColumn) => {
          if (measure?.Props?.Measure?.IsPreDefined) {
            const selectedMeasure = action.payload.res.Visualization.Columns.find((col: IColumn) => col.Id === measure.Id);
            measure.Props.DrilldownEnabled = measure.Props?.AllowDrillDown;
            measure.Props.Measure.Entity = selectedMeasure?.Props?.Measure?.Entity;
            measure.Props.Measure.JoinID = selectedMeasure?.Props?.Measure?.JoinID;
            return measure;
          }
          return measure;
        }) : [];
        state.reportBuilderInfo.additionalMeasures = isValidArrayAndNotEmpty(state?.reportBuilderInfo.additionalMeasures) ? state.reportBuilderInfo.additionalMeasures.map((measure: IColumn) => {
          if (measure?.Props?.Measure?.IsPreDefined) {
            const selectedMeasure = action.payload.res?.Visualization?.Columns?.find((col: IColumn) => col.Id === measure.Id);
            measure.Props.DrilldownEnabled = measure.Props?.AllowDrillDown;
            measure.Props.Measure.Entity = selectedMeasure?.Props?.Measure?.Entity;
            measure.Props.Measure.JoinID = selectedMeasure?.Props?.Measure?.JoinID;
            return measure;
          }
          return measure;
        }) : [];
      }
      state.previewBackUpInfo.filterBuilder = state.filterBuilder;
      state.previewBackUpInfo.updatedReportBuilderInfo = state.updatedReportBuilderInfo;
      state.requestProcessing[ReportBuilderActions.GET_PREVIEW_DATA] = APIRequestStatus.Success;
    },
    [ReportBuilderActions.SET_PREVIEW_DATA_FAILURE_STATE]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_PREVIEW_DATA] = APIRequestStatus.Failure;
    },
    [ReportBuilderActions.ADD_DRILLDOWN_CONFIGURATION]: (state: IReportBuilderState, action:PayloadAction<{id: string}>) => {
      const emptyDrilldownConfigExists = Object.keys(state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping).find((item: any) => state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping[item].length < 1);

      if (!emptyDrilldownConfigExists) {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = {
          ...state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping,
          [action.payload.id]: [],
        };
      }
    },
    [ReportBuilderActions.ADD_DRILLDOWN_MEASURE]: (state:IReportBuilderState, action: PayloadAction<{data: IColumn, key: string}>) => {
      const drilldownMeasures = cloneDeep(state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping[action.payload.key]) || [];
      const data = action.payload.data;
      if (!drilldownMeasures?.some((measure: MeasureListWithJoinId) => measure?.Id === data?.Id)) {
        drilldownMeasures.push({
          Id: data.Id, Name: data?.Props?.Measure?.Name, JoinId: data?.Props?.Measure?.JoinID || drilldownMeasures[0]?.JoinId || '', Entity: drilldownMeasures[0]?.Entity,
        });
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping[action.payload.key] = drilldownMeasures;
        state.updatedReportBuilderInfo.appliedMeasures = state?.updatedReportBuilderInfo?.appliedMeasures?.map((measure: IColumn) => {
          if (drilldownMeasures?.some((col: MeasureListWithJoinId) => col?.Id === measure?.Id)) {
            measure.Props.DrilldownEnabled = true;
          }
          return measure;
        });
      }
    },
    [ReportBuilderActions.REMOVE_DRILLDOWN_ACCORD]: (state:IReportBuilderState, action: PayloadAction<string>) => {
      const { drilldownConfigMapping, drilldownColumnConfig } = state.updatedReportBuilderInfo.drilldownConfigInfo || {};
      state.updatedReportBuilderInfo.appliedMeasures = state?.updatedReportBuilderInfo?.appliedMeasures?.map((measure: IColumn) => {
        if (drilldownConfigMapping[action.payload]?.some((col: MeasureListWithJoinId) => col?.Id === measure?.Id)) {
          measure.Props.DrilldownEnabled = false;
        }
        return measure;
      });
      delete drilldownConfigMapping[action.payload];
      delete drilldownColumnConfig[action.payload];
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = drilldownConfigMapping;
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = drilldownColumnConfig;
    },
    [ReportBuilderActions.REMOVE_DRILLDOWN_MEASURE]: (state:IReportBuilderState, action: PayloadAction<{id: string, key: string}>) => {
      const drilldownConfigItem = state.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping?.[action.payload.key] || [];
      const removedMeasure = [...drilldownConfigItem]?.find((measure) => measure?.Id === action.payload.id);
      const drilldownMeasures = [...drilldownConfigItem].filter((measure) => measure?.Id !== action.payload.id);
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping[action.payload.key] = drilldownMeasures;
      state.updatedReportBuilderInfo.appliedMeasures = state?.updatedReportBuilderInfo?.appliedMeasures?.map((measure: IColumn) => {
        if (removedMeasure?.Id === measure?.Id) {
          measure.Props.DrilldownEnabled = false;
        }
        return measure;
      });
    },

    [ReportBuilderActions.INIT_DRILLDOWN_MAPPING]: (state: IReportBuilderState) => {
      if (isEmpty(state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping)) {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = getDrilldownMapping(state?.updatedReportBuilderInfo?.appliedMeasures || [], state);
      } else {
        const firstDrilldownKey = Object.keys(state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping)[0];
        const firstDrilldownValue = state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping[firstDrilldownKey];
        if (isString(firstDrilldownValue[0])) { // check if structure of value is ["measureName1","measureName2"] and convert to {Name:"measureName",Id:"measureId",JoinId:"measureJoinId",Entity:"measureEntity"}
          state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = convertDrilldownMappingIntoIdMapping(state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping, state?.updatedReportBuilderInfo?.appliedMeasures);
        }
      }

      state.updatedReportBuilderInfo.drilldownConfigInfo.enableDrilldown = state?.updatedReportBuilderInfo?.appliedMeasures?.some((measure: IColumn) => (Object.prototype.hasOwnProperty.call(measure?.Props, 'DrilldownEnabled') ? measure?.Props?.DrilldownEnabled : false));

      const newMapper: DrilldownConfigMapping = {};
      Object.keys(state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping || {})?.forEach((key) => {
        const measureInfo = state?.updatedReportBuilderInfo?.appliedMeasures?.find((measure:IColumn) => measure?.Id === state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping[key]?.[0]?.Id);
        newMapper[key] = state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownConfigMapping[key]?.map((measure: MeasureListWithJoinId) => {
          let entity = measureInfo?.Props?.Measure?.Entity;
          let joinId = measureInfo?.Props?.Measure?.JoinID;
          if (measureInfo?.Props?.Measure?.IsCustomExpression) {
            const defaultEntity = getDefaultEntity(state?.updatedReportBuilderInfo?.selectedTemplate?.ReportConfig);
            const fieldsData = fieldSelectors({ reportBuilder: state } as IGlobalState);
            const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fieldsData || [], measureInfo, defaultEntity);
            entity = Entity;
            joinId = JoinId;
          }
          return {
            ...measure,
            JoinId: joinId || '',
            Entity: entity || '',
          };
        });
      });
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = newMapper;
    },
    [ReportBuilderActions.SORT_DRILLDOWN_COLUMN]: (state: IReportBuilderState, action: PayloadAction<ISortDrilldownColumnPayload>) => {
      const { key } = action.payload;
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig[key].sortState = sortDrilldownColumns(state, action.payload);
    },
    [ReportBuilderActions.ADD_REMOVE_DRILLDOWN_FIELD]: (state: IReportBuilderState, action: PayloadAction<DrilldownFieldUpdationsAction>) => {
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig[action.payload.groupingId] = getUpdatedDrilldownColConfig(state, action.payload);
    },
    [ReportBuilderActions.UPDATE_DRILLDOWN_COLUMN]: (state:IReportBuilderState, action: PayloadAction<{
      index: number;
      newMeasure: IColumn;
      key: string;
    }>) => {
      state.updatedReportBuilderInfo.drilldownConfigInfo = getStateOnUpdateDrilldownColumn(state?.updatedReportBuilderInfo?.drilldownConfigInfo, action.payload);
    },
    [ReportBuilderActions.MOVE_DRILLDOWN_COLUMN]: (state:IReportBuilderState, action: PayloadAction<{startElement:IColumn;
      endElement:IColumn;
      key: string;}>) => {
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = {
        ...state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig,
        [action.payload.key]: {
          ...state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig[action.payload.key],
          defaultColumns: getStateOnMoveColumns(state?.updatedReportBuilderInfo?.drilldownConfigInfo?.drilldownColumnConfig, action.payload),
        },
      };
    },
    [ReportBuilderActions.TOGGLE_DRILLDOWN]: (state:IReportBuilderState) => {
      const isEnabled = !state.updatedReportBuilderInfo.drilldownConfigInfo.enableDrilldown;
      const calculatedMeasuresList = state.updatedReportBuilderInfo.selectedTemplate.ReportConfig.CalculatedColumns;
      const isCalculated = fromPairs((calculatedMeasuresList || []).map((column) => [column?.Alias, true]));
      const builderMeasuresList = state.updatedReportBuilderInfo.reportBuilderSavePayload?.Builder[0]?.Select?.Measures || [];
      const updatedAppliedColumns = state?.updatedReportBuilderInfo?.appliedMeasures?.map((measure: IColumn) => {
        let expr = measure?.Props?.Measure?.Expression;
        if (!expr) {
          const currentMeasure = builderMeasuresList.find((builderMeasure: IBuilderMeasure) => builderMeasure.Alias === measure.Name);
          expr = currentMeasure?.MeasureExpression;
        }
        measure.Props.DrilldownEnabled = isCalculated[measure.Name] || !isDrilldownPossible(expr) ? false : !!isEnabled;
        return measure;
      });

      const filteredAppliedMeasures = updatedAppliedColumns.filter((col: IColumn) => !isCalculated[col.Name]);
      state.updatedReportBuilderInfo.appliedMeasures = updatedAppliedColumns;
      state.updatedReportBuilderInfo.drilldownConfigInfo.enableDrilldown = isEnabled;
      if (isEnabled) {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = getDrilldownMapping(filteredAppliedMeasures, state);
      } else {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = {};
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = {};
      }
    },
    [ReportBuilderActions.UPDATE_DRILLDOWN_PAGINATION]: (state: IReportBuilderState, action: PayloadAction<number>) => {
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownPagination.PageSize = action.payload;
    },
    [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS] = APIRequestStatus.Processing;
    },
    [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS_SUCCESS]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS] = APIRequestStatus.Success;
    },
    [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS_FAILURE]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS] = APIRequestStatus.Failure;
    },
    [ReportBuilderActions.RESET_GET_RAW_TEMPLATE_DETAILS]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS] = APIRequestStatus.AboutToLoad;
    },
    [ReportBuilderActions.SWITCH_USER_FIELD_AND_FILTER]: (state: IReportBuilderState, action: PayloadAction<ISwitchUserFieldFilterPayload>) => getStateOnSwitchUserFilter(state, action.payload),
    [ReportBuilderActions.UPDATE_DATA_SOURCE_CHANGES]: (state: IReportBuilderState, action: PayloadAction<IResetReportConfigPayload>) => {
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Builder[0].Namespace = action.payload.primaryNamespace;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Builder[0].Joins = action.payload.joinsData;
      state.updatedReportBuilderInfo.selectedTemplate.ReportConfig.Builder[0].Namespace = action.payload.primaryNamespace;
      state.updatedReportBuilderInfo.selectedTemplate.ReportConfig.Builder[0].Joins = action.payload.joinsData;
    },
    [ReportBuilderActions.TOGGLE_DATA_SOURCE_EDIT_MODE]: (state: IReportBuilderState, action: PayloadAction<boolean>) => {
      state.dataSourceInfo.isDataSourceEditMode = action.payload || false;
    },
    [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST] = APIRequestStatus.Processing;
    },
    [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_FAILURE]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST] = APIRequestStatus.Failure;
    },
    [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_SUCCESS]: (state:IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST] = APIRequestStatus.Success;
    },
    [ReportBuilderActions.UPDATE_REPORT_METADATA_POST_JOINS_SAVE]: (state:IReportBuilderState, action: PayloadAction<ObjModel.Obj>) => {
      const title = action.payload.Title;
      const description = action.payload.Description;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Details.Title = title;
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Details.Description = description;
      state.updatedReportBuilderInfo.drilldownConfigInfo = {
        drilldownConfigMapping: {},
        drilldownColumnConfig: {},
        enableDrilldown: true,
        drilldownPagination: defaultPageValue,
      };
      state.updatedReportBuilderInfo.expressionStore = {};
    },
  },
  extraReducers: (builder) => {
    // case ReportBuilderActions.INITIAL_SAVE_TEMPLATE:
    builder.addCase(getReportTemplateList.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_TEMPLATE_LIST] = APIRequestStatus.Processing;
    });

    builder.addCase(getReportTemplateList.fulfilled, (state: IReportBuilderState, action: PayloadAction<ITemplateList[]>) => {
      state.reportBuilderInfo.templateList = action.payload;
      state.requestProcessing[ReportBuilderActions.GET_TEMPLATE_LIST] = APIRequestStatus.Success;
    });

    builder.addCase(getReportTemplateList.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_TEMPLATE_LIST] = APIRequestStatus.Failure;
    });

    builder.addCase(saveReportConfig.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_REPORT_CONFIG] = APIRequestStatus.Processing;
    });

    builder.addCase(saveReportConfig.fulfilled, (state: IReportBuilderState) => {
      state.updatedReportBuilderInfo.reportBuilderSavePayload.Status = ReportBuilderStatus.PUBLISHED;
      state.assortedInfo.reportBuilderSaveStatus = ReportBuilderSaveStatus.CREATED;
      state.requestProcessing[ReportBuilderActions.SAVE_REPORT_CONFIG] = APIRequestStatus.Success;
    });

    builder.addCase(saveReportConfig.rejected, (state: IReportBuilderState, action: PayloadAction<any>) => {
      state.updatedReportBuilderInfo.reportBuilderSavePayload = resetFilterOnFailure(state.updatedReportBuilderInfo.reportBuilderSavePayload, state);
      if (action.payload?.status !== statusCodes.Forbidden) {
        state.requestProcessing[ReportBuilderActions.SAVE_REPORT_CONFIG] = APIRequestStatus.Failure;
      } else {
        state.requestProcessing[ReportBuilderActions.SAVE_REPORT_CONFIG] = APIRequestStatus.AboutToLoad;
      }
    });

    builder.addCase(getReportBuilderDynamicFilters.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_FILTERS] = APIRequestStatus.Processing;
    });

    builder.addCase(getReportBuilderDynamicFilters.fulfilled, (state: IReportBuilderState, action: PayloadAction<IFilterConfig[]>) => {
      state.reportBuilderInfo.additionalFilters = action.payload;
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_FILTERS] = APIRequestStatus.Success;
    });

    builder.addCase(getReportBuilderDynamicFilters.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_FILTERS] = APIRequestStatus.Failure;
    });

    builder.addCase(getDynamicDimensions.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS] = APIRequestStatus.Processing;
    });

    builder.addCase(getDynamicDimensions.fulfilled, (state: IReportBuilderState, action: PayloadAction<IColumn[]>) => {
      state.reportBuilderInfo.additionalDimensions = action.payload;
      state.updatedReportBuilderInfo.appliedDimensions = getAppliedDimensionsOnSuccess(
        state,
        action.payload,
      );
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS] = APIRequestStatus.Success;
    });

    builder.addCase(getDynamicDimensions.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS] = APIRequestStatus.Failure;
    });

    builder.addCase(getDynamicMeasures.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_MEASURES] = APIRequestStatus.Processing;
    });

    builder.addCase(getDynamicMeasures.fulfilled, (state: IReportBuilderState, action: PayloadAction<DynamicMeasureResponse[]>) => {
      state.reportBuilderInfo.additionalMeasures = [
        ...getMeasureColumns(state.updatedReportBuilderInfo.selectedTemplate.ReportConfig, false, true),
        ...action.payload.map((res) => buildMeasureColumn(
          res.Id,
          res.DisplayName,
          res.Alias,
          res.DataType,
          'Sum',
          dynamicMeasureDefaultConfig,
          res?.Entity,
          res.SchemaName,
          res.JoinID,
        )),
      ];
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_MEASURES] = APIRequestStatus.Success;
    });

    builder.addCase(getDynamicMeasures.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_MEASURES] = APIRequestStatus.Failure;
    });

    builder.addCase(getFieldsRequest.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_FIELDS] = APIRequestStatus.Processing;
    });

    builder.addCase(getFieldsRequest.fulfilled, (state: IReportBuilderState, action: PayloadAction<IField[]>) => {
      state.reportBuilderInfo.fields = action.payload;
      state.requestProcessing[ReportBuilderActions.GET_FIELDS] = APIRequestStatus.Success;
    });

    builder.addCase(getFieldsRequest.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_FIELDS] = APIRequestStatus.Failure;
    });

    builder.addCase(getReportRawConfig.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_REPORT_RAW_CONFIG_REQUEST] = APIRequestStatus.Processing;
    });

    builder.addCase(getReportRawConfig.fulfilled, (state: IReportBuilderState, action: PayloadAction<SieraResponse<IReportConfig>>) => {
      state.updatedReportBuilderInfo.reportBuilderSavePayload = action.payload.data;
      state.reportEditInfo.rawReportConfig = { ...action.payload.data, Columns: action.payload?.data?.Columns?.filter((col:IColumn) => !(col?.Props?.Measure?.IsCustomExpression && col?.Props?.Mode === ColumnModes.NotApplied)) || [] };
      state.requestProcessing[ReportBuilderActions.GET_REPORT_RAW_CONFIG_REQUEST] = APIRequestStatus.Success;
    });

    builder.addCase(getReportRawConfig.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_REPORT_RAW_CONFIG_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getReportRenderConfig.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_REPORT_RENDER_CONFIG_REQUEST] = APIRequestStatus.Processing;
    });

    builder.addCase(getReportRenderConfig.fulfilled, (state: IReportBuilderState, action: PayloadAction<SieraResponse<ReportResponseModel.IReportConfig>, string, ObjModel.Obj>) => {
      const reportConfig = { ...action.payload.data, Columns: action.payload?.data?.Columns?.filter((col:IColumn) => !(col?.Props?.Measure?.IsCustomExpression && col?.Props?.Mode === ColumnModes.NotApplied)) || [] };
      const variables = action.meta.arg;
      const data = action.payload.data;
      state.filterBuilder.appliedFilters = getAppliedFilterFromTemplate(state, variables, reportConfig.Filters, reportConfig?.FiltersMetadata?.UserDropdownSelectType);
      state.filterBuilder.filterConfig = getUpdatedReportFilterConfig(reportConfig.Filters);
      state.reportEditInfo.renderReportConfig = reportConfig;
      state.requestProcessing[ReportBuilderActions.GET_REPORT_RENDER_CONFIG_REQUEST] = APIRequestStatus.Success;
      state.updatedReportBuilderInfo.drilldownConfigInfo.enableDrilldown = !!data?.Columns?.some((col) => (Object.prototype.hasOwnProperty.call(col?.Props, 'DrilldownEnabled') ? col?.Props?.DrilldownEnabled : false));
      if (isValidObjectAndNotEmpty(data?.Visualization?.DrilldownConfigIdMapping)) {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = data?.Visualization?.DrilldownConfigIdMapping || null;
      } else if (data?.Visualization?.DrillDownConfigMapping) {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = data?.Visualization?.DrillDownConfigMapping || null;
      }

      if (data?.Visualization?.DrillDownPagination) {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownPagination = data?.Visualization?.DrillDownPagination;
      } else {
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownPagination = defaultPageValue;
      }
    });

    builder.addCase(getReportRenderConfig.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_REPORT_RENDER_CONFIG_REQUEST] = APIRequestStatus.Failure;
    });

    builder.addCase(getReportBuilderFiltersLoad.pending, (state: IReportBuilderState, action: PayloadAction<any, string, ObjModel.Obj>) => {
      const { filterId, isLazyLoad } = action.meta.arg;
      const filtersLoading = state.requestProcessing[ReportBuilderActions.FILTER_DATA_LOAD_REQUEST];
      if (isLazyLoad) {
        filtersLoading[filterId] = APIRequestStatus.LazyLoading;
        state.requestProcessing[
          ReportBuilderActions.FILTER_DATA_LOAD_REQUEST
        ] = filtersLoading;
      } else {
        filtersLoading[filterId] = APIRequestStatus.Processing;
        state.requestProcessing[
          ReportBuilderActions.FILTER_DATA_LOAD_REQUEST
        ] = filtersLoading;
      }
    });

    builder.addCase(getReportBuilderFiltersLoad.fulfilled, (state: IReportBuilderState, action: PayloadAction<IFilterResponse, string, ObjModel.Obj>) => {
      const filterResponse = action.payload;
      const filterId = action?.meta?.arg?.filterId;
      const isLazyLoad = action?.meta?.arg?.isLazyLoad;
      const newState = state;
      newState.filterBuilder = cloneDeep(state?.filterBuilder);
      if (isLazyLoad && state?.filterBuilder?.appliedFilters[filterId]) {
        filterResponse.FilterResponse = newState.filterBuilder.appliedFilters[filterId]?.FilterResponse;
      }
      const filtersLoading = state.requestProcessing[
        ReportBuilderActions.FILTER_DATA_LOAD_REQUEST
      ];
      const newAppliedFilters = state.filterBuilder.appliedFilters;
      filtersLoading[filterId] = APIRequestStatus.Success;
      let isUsersModifiedFlag = false;
      Object.values(filtersLoading).forEach((status) => {
        if (status === APIRequestStatus.Processing) isUsersModifiedFlag = true;
      });
      state.filterBuilder.isUsersModified = isUsersModifiedFlag;
      newAppliedFilters[filterId] = {
        ...filterResponse,
        label: newAppliedFilters[filterId]?.label,
        userStatus: newAppliedFilters[filterId]?.userStatus,
      };
      state.filterBuilder.appliedFilters = newAppliedFilters;
      state.requestProcessing[
        ReportBuilderActions.FILTER_DATA_LOAD_REQUEST
      ] = filtersLoading;
      const { Title, Description } = state.updatedReportBuilderInfo.reportBuilderSavePayload.Details;
      state.assortedInfo.isFormDisabled = getIsReportBuilderSaveFormDisable(Title, Description, state)
        || getIsFilterFormDisable(state.filterBuilder);
    });

    builder.addCase(getReportBuilderFiltersLoad.rejected, (state: IReportBuilderState, action: PayloadAction<any, string, ObjModel.Obj>) => {
      const filterId = action.meta.arg;
      const filtersLoading = state.requestProcessing[ReportBuilderActions.FILTER_DATA_LOAD_REQUEST];
      filtersLoading[filterId] = APIRequestStatus.Failure;
      state.requestProcessing[
        ReportBuilderActions.FILTER_DATA_LOAD_REQUEST
      ] = filtersLoading;
    });

    builder.addCase(getAdditionalDrilldownColumnsRequest.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_DRILLDOWN_COLUMN] = APIRequestStatus.Processing;
    });
    builder.addCase(getAdditionalDrilldownColumnsRequest.fulfilled, (state: IReportBuilderState, action: PayloadAction<IColumn[], string, any>) => {
      const groupId = action?.meta?.arg?.groupId;
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_DRILLDOWN_COLUMN] = APIRequestStatus.Success;
      const defaultCols = state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig[groupId]?.defaultColumns || [];
      const updatedAdditionalCols = isValidArrayAndNotEmpty(action.payload) ? action.payload.filter((col: IColumn) => col.Props.Mode !== ColumnModes.Hidden) : [];
      const updatedAddtFields = updatedAdditionalCols?.map((field: IColumn) => {
        const col = defaultCols?.find((item: IColumn) => getUniqIdentiferForDrilldownCols(item) === getUniqIdentiferForDrilldownCols(field));
        const isAppliedField = col ? DimensionStatus.Applied : DimensionStatus.NotApplied;
        return {
          ...field,
          Props: {
            ...field.Props,
            Dimension: {
              Applied: isAppliedField,
            },
          },
        };
      });

      const updatedDrilldownColumnConfig = {
        ...state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig,
        [groupId]: {
          ...state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig[groupId],
          additionalDrilldownColumns: updatedAddtFields,
          isAdditionalColumnFetched: true,
        },
      };
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = updatedDrilldownColumnConfig;
    });
    builder.addCase(getAdditionalDrilldownColumnsRequest.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_DRILLDOWN_COLUMN] = APIRequestStatus.Failure;
    });

    builder.addCase(getDefaultDrilldownColumnsRequest.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_DEFAULT_DRILLDOWN_COLUMN] = APIRequestStatus.Processing;
    });
    builder.addCase(getDefaultDrilldownColumnsRequest.fulfilled, (state: IReportBuilderState, action: PayloadAction<any, string, ObjModel.Obj>) => {
      state.requestProcessing[ReportBuilderActions.GET_DEFAULT_DRILLDOWN_COLUMN] = APIRequestStatus.Success;
      state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = {
        ...state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig,
        ...getInitialDrilldownConfigInfo(action.payload, state?.updatedReportBuilderInfo?.drilldownConfigInfo),
      };
    });
    builder.addCase(getDefaultDrilldownColumnsRequest.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_DEFAULT_DRILLDOWN_COLUMN] = APIRequestStatus.Failure;
    });
    builder.addCase(getDrilldownConfig.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_DRILLDOWN_CONFIG] = APIRequestStatus.Processing;
    });
    builder.addCase(getDrilldownConfig.fulfilled, (state: IReportBuilderState, action: PayloadAction<any>) => {
      state.requestProcessing[ReportBuilderActions.GET_DRILLDOWN_CONFIG] = APIRequestStatus.Success;
      if (action.payload?.data?.message || isEmpty(action.payload)) {
        state.updatedReportBuilderInfo.appliedMeasures = state?.updatedReportBuilderInfo?.appliedMeasures?.map((measure) => {
          measure.Props.DrilldownEnabled = isDrilldownPossible(measure?.Props?.Measure?.Expression) && state?.updatedReportBuilderInfo?.drilldownConfigInfo?.enableDrilldown;
          return measure;
        });
      } else {
        const { configMapping, drilldownColumns } = getUpdatedFetchdrilldownColumnsRes(action.payload.data,
          state.updatedReportBuilderInfo.appliedMeasures, state.updatedReportBuilderInfo.drilldownConfigInfo, state.updatedReportBuilderInfo.selectedTemplate.ReportConfig.CalculatedColumns, state);

        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownConfigMapping = configMapping;
        state.updatedReportBuilderInfo.drilldownConfigInfo.drilldownColumnConfig = drilldownColumns;
      }
    });
    builder.addCase(getDrilldownConfig.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_DRILLDOWN_CONFIG] = APIRequestStatus.Failure;
    });
    builder.addCase(saveDrilldownColumnConfig.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_DRILLDOWN_CONFIG] = APIRequestStatus.Processing;
    });
    builder.addCase(saveDrilldownColumnConfig.fulfilled, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_DRILLDOWN_CONFIG] = APIRequestStatus.Success;
    });
    builder.addCase(saveDrilldownColumnConfig.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.SAVE_DRILLDOWN_CONFIG] = APIRequestStatus.Failure;
    });
    builder.addCase(getReportBuilderDynamicUserFilters.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_USER_FIELD_FILTERS] = APIRequestStatus.Processing;
    });

    builder.addCase(getReportBuilderDynamicUserFilters.fulfilled, (state: IReportBuilderState, action: PayloadAction<IFilterConfig[]>) => {
      const appliedUserFilter = isValidArrayAndNotEmpty(state.filterBuilder.filterConfig) && state.filterBuilder.filterConfig.find((item:IFilterConfig) => item.Type === FilterType.UserMultiSelect);
      const additionalUserFilters = action.payload;
      if (isValidArrayAndNotEmpty(additionalUserFilters)) {
        state.reportBuilderInfo.additionalUserFieldFilters = additionalUserFilters;
        state.reportBuilderInfo.additionalUserFieldFilters.push(appliedUserFilter);
      }
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_USER_FIELD_FILTERS] = APIRequestStatus.Success;
    });

    builder.addCase(getReportBuilderDynamicUserFilters.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_USER_FIELD_FILTERS] = APIRequestStatus.Failure;
    });

    builder.addCase(getReportBuilderDynamicUserDimensions.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_USER_DIMENSIONS] = APIRequestStatus.Failure;
    });

    builder.addCase(getReportBuilderDynamicUserDimensions.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_USER_DIMENSIONS] = APIRequestStatus.Processing;
    });

    builder.addCase(getReportBuilderDynamicUserDimensions.fulfilled, (state: IReportBuilderState, action: PayloadAction<IColumn[]>) => {
      const additionalUserFields = isValidArrayAndNotEmpty(action.payload) ? action.payload.map((item: IColumn) => ({ ...item, Props: { ...item.Props, IsFieldSwitch: true } })) : [];
      if (isValidArrayAndNotEmpty(additionalUserFields)) {
        state.reportBuilderInfo.additionalUserFields = additionalUserFields;
        state.updatedReportBuilderInfo.appliedDimensions = [...getAppliedDimensionsOnSuccess(state, additionalUserFields)];
      }
      state.requestProcessing[ReportBuilderActions.GET_ADDITIONAL_USER_DIMENSIONS] = APIRequestStatus.Success;
    });
    builder.addCase(getDataSourcesList.pending, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_DATA_SOURCES_REQUEST] = APIRequestStatus.Processing;
    });
    builder.addCase(getDataSourcesList.fulfilled, (state: IReportBuilderState, action: PayloadAction<IDataSourceApiResponse[]>) => {
      state.requestProcessing[ReportBuilderActions.GET_DATA_SOURCES_REQUEST] = APIRequestStatus.Success;
      state.dataSourceInfo.dataSources = getUpdatedDataSourceResponse(action.payload);
    });
    builder.addCase(getDataSourcesList.rejected, (state: IReportBuilderState) => {
      state.requestProcessing[ReportBuilderActions.GET_DATA_SOURCES_REQUEST] = APIRequestStatus.Failure;
    });
  },
});

export const {
  [ReportBuilderActions.SEARCH_FILTER_DATA_LOAD_REQUEST]: reportBuilderSearchFilter,
  [ReportBuilderActions.LOAD_MORE_FILTER_DATA_LOAD_REQUEST]: reportBuilderLoadMoreFilters,
  [ReportBuilderActions.ADD_DYNAMIC_FILTER]: reportBuilderAddDynamicFilter,
  [ReportBuilderActions.SWITCH_USER_FIELD_AND_FILTER]: reportBuilderSwitchUserFieldFilter,
  [ReportBuilderActions.DELETE_DYNAMIC_FILTER]: reportBuilderDeleteDynamicFilter,
  [ReportBuilderActions.FILTER_VALUE_CHANGE]: reportBuilderFilterValueChange,
  [ReportBuilderActions.UPDATE_FILTER_FORM]: reportBuilderUpdateFilterForm,
  [ReportBuilderActions.UPDATED_SELECTED_TEMPLATE]: reportBuilderUpdateSelectedTemplate,
  [ReportBuilderActions.UPDATE_SELECTED_TAB]: reportBuilderUpdateSelectedTab,
  [ReportBuilderActions.UPDATE_REPORT_TEMPLATE_DETAIL]: reportBuilderUpdateReportTemplateDetail,
  [ReportBuilderActions.UPDATE_VISUALIZATION]: reportBuilderUpdateVisualization,
  [ReportBuilderActions.UPDATE_PAGINATION]: reportBuilderUpdatePagination,
  [ReportBuilderActions.RESET_REPORT_TEMPLATE]: reportBuilderResetTemplate,
  [ReportBuilderActions.SET_DIMENSIONS_GROUPING]: reportBuilderDimensionGrouping,
  [ReportBuilderActions.SET_DIMENSIONS_BINNING]: reportBuilderDimensionBinning,
  [ReportBuilderActions.ADD_MEASURE]: reportBuilderAddMeasure,
  [ReportBuilderActions.REM_MEASURE]: reportBuilderRemoveMeasure,
  [ReportBuilderActions.SET_MEASURE]: reportBuilderSetMeasure,
  [ReportBuilderActions.MOV_MEASURE]: reportBuilderMoveMeasure,
  [ReportBuilderActions.UPDATE_SAVE_REPORT_BUILDER_STATUS]: reportBuilderSetSaveStatus,
  [ReportBuilderActions.SORT_COLUMN]: reportBuilderSortColumn,
  [ReportBuilderActions.DELETE_CUSTOM_EXPRESSION]: reportBuilderDeleteExpression,
  [ReportBuilderActions.ADD_OR_UPDATE_CUSTOM_EXPRESSION]: reportBuilderAddUpdateCustomMeasure,
  [ReportBuilderActions.COMBINE_RAW_AND_RENDER_CONFIG]: reportBuilderCombineConfigs,
  [ReportBuilderActions.SET_REPORT_EDIT_ID]: reportBuilderSetReportId,
  [ReportBuilderActions.SET_TEMPORARY_REPORT_ID]: reportBuilderSetTempId,
  [ReportBuilderActions.GET_PREVIEW_DATA]: reportBuilderPreviewLoadState,
  [ReportBuilderActions.SET_PREVIEW_DATA_SUCCESS_STATE]: reportBuilderPreviewSuccessState,
  [ReportBuilderActions.SET_PREVIEW_DATA_FAILURE_STATE]: reportBuilderPreviewFailureState,
  [ReportBuilderActions.SET_INITIAL_SUCCESS_STATE]: reportBuilderInitialSuccessState,
  [ReportBuilderActions.SET_INITIAL_FAILURE_STATE]: reportBuilderInitialFailureState,
  [ReportBuilderActions.INITIAL_SAVE_TEMPLATE]: reportBuilderInitialLoadState,
  [ReportBuilderActions.ADD_DRILLDOWN_CONFIGURATION]: addDrilldownConfiguration,
  [ReportBuilderActions.ADD_DRILLDOWN_MEASURE]: addDrilldownMeasure,
  [ReportBuilderActions.REMOVE_DRILLDOWN_ACCORD]: removeAccord,
  [ReportBuilderActions.REMOVE_DRILLDOWN_MEASURE]: removeDrilldownMeasure,
  [ReportBuilderActions.INIT_DRILLDOWN_MAPPING]: initDrilldownMapping,
  [ReportBuilderActions.SORT_DRILLDOWN_COLUMN]: drilldownSortColumn,
  [ReportBuilderActions.ADD_REMOVE_DRILLDOWN_FIELD]: addRemoveDrilldownField,
  [ReportBuilderActions.UPDATE_DRILLDOWN_COLUMN]: updateDrilldownColumn,
  [ReportBuilderActions.MOVE_DRILLDOWN_COLUMN]: moveDrilldownColumn,
  [ReportBuilderActions.TOGGLE_DRILLDOWN]: toggleDrilldown,
  [ReportBuilderActions.UPDATE_DRILLDOWN_PAGINATION]: updateDrildownPagination,
  [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS]: getRawTemplateDetailsRequest,
  [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS_SUCCESS]: getRawTemplateDetailsSuccess,
  [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS_FAILURE]: getRawTemplateDetailsFailure,
  [ReportBuilderActions.RESET_GET_RAW_TEMPLATE_DETAILS]: resetGetRawTemplateDetails,
  [ReportBuilderActions.UPDATE_DATA_SOURCE_CHANGES]: updateDataSourceChanges,
  [ReportBuilderActions.TOGGLE_DATA_SOURCE_EDIT_MODE]: toggleDataSourceEditMode,
  [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST]: saveDataSourceChangesRequest,
  [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_SUCCESS]: saveDataSourceChangesSuccess,
  [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_FAILURE]: saveDataSourceChangesFailure,
  [ReportBuilderActions.UPDATE_REPORT_METADATA_POST_JOINS_SAVE]: updateReportMetadataPostJoinsSave,
} = reportBuilderSlice.actions;

export default reportBuilderSlice;
