import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  fill1?: string;
}

const DataSettingHighlightedIcon = ({
  width, height, fill, fill1,
}: Props) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="se-data-settings-icon">
    <g clipPath="url(#clip0_7107_2510)">
      <path id="Path_4" fill={fill1} d="M2.48291 4.55771C2.48291 4.51289 2.51923 4.47656 2.56406 4.47656H4.49459V6.50435H2.48291V4.55771Z" />
      <path id="Path_4" fill={fill1} d="M13.8851 4.56064C13.8851 4.51582 13.8487 4.47949 13.8039 4.47949H6.06482V6.50442H13.8851V4.56064Z" />
      <path id="Path_4" fill={fill1} d="M15.3756 8.07056H6.06482V15.9552H11.5181C13.6486 15.9552 15.3756 14.2281 15.3756 12.0977V8.07056Z" />
      <path id="Path_4" fill={fill1} d="M2.48291 14.3813C2.48291 14.4262 2.51923 14.4625 2.56406 14.4625H4.49459V8.07007H2.48291V14.3813Z" />
      <path id="Path_5" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M0.992188 4.56804C0.992188 3.69826 1.69668 2.99316 2.56573 2.99316H13.8021C14.6712 2.99316 15.3757 3.69826 15.3757 4.56804V8.04039C15.3757 8.45239 15.0419 8.78638 14.6303 8.78638C14.2186 8.78638 13.8849 8.45239 13.8849 8.04039V4.56804C13.8849 4.52226 13.8478 4.48515 13.8021 4.48515H2.56573C2.51999 4.48515 2.48291 4.52226 2.48291 4.56804V14.3802C2.48291 14.426 2.51999 14.4631 2.56573 14.4631H7.46764C7.8793 14.4631 8.21301 14.7971 8.21301 15.2091C8.21301 15.6211 7.8793 15.9551 7.46764 15.9551H2.56573C1.69668 15.9551 0.992188 15.25 0.992188 14.3802V4.56804Z" />
      <path id="Path_5" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M0.992188 4.56804C0.992188 3.69826 1.69668 2.99316 2.56573 2.99316H13.8021C14.6712 2.99316 15.3757 3.69826 15.3757 4.56804V6.49463C15.3757 7.36441 14.6712 8.0695 13.8021 8.0695H2.56573C1.69668 8.0695 0.992188 7.36441 0.992188 6.49463V4.56804ZM2.56573 4.48515C2.51999 4.48515 2.48291 4.52226 2.48291 4.56804V6.49463C2.48291 6.54041 2.51999 6.57752 2.56573 6.57752H13.8021C13.8478 6.57752 13.8849 6.54041 13.8849 6.49463V4.56804C13.8849 4.52226 13.8478 4.48515 13.8021 4.48515H2.56573Z" />
      <path id="Path_5" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M0.992188 4.56804C0.992188 3.69826 1.69668 2.99316 2.56573 2.99316H4.49068C5.35972 2.99316 6.06422 3.69826 6.06422 4.56804V14.3802C6.06422 15.25 5.35973 15.9551 4.49068 15.9551H2.56573C1.69668 15.9551 0.992188 15.25 0.992188 14.3802V4.56804ZM2.56573 4.48515C2.51999 4.48515 2.48291 4.52226 2.48291 4.56804V14.3802C2.48291 14.426 2.51999 14.4631 2.56573 14.4631H4.49068C4.53642 14.4631 4.5735 14.426 4.5735 14.3802V4.56804C4.5735 4.52226 4.53642 4.48515 4.49068 4.48515H2.56573Z" />
      <path id="Path_6" d="M4.49488 4.4856H6.0651V14.4625H4.49488V4.4856Z" fill={fill} />
      <path id="Path_6" d="M13.8851 6.50441V8.07067H2.48291V6.50441H13.8851Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.09 9.09781C14.5009 8.67638 15.1671 8.67638 15.578 9.09781L16.962 10.5173C17.3728 10.9387 17.3728 11.622 16.962 12.0434L11.3625 17.7865C11.1652 17.9888 10.8975 18.1025 10.6185 18.1025H9.23454C8.65345 18.1025 8.18237 17.6194 8.18237 17.0234V15.6039C8.18237 15.3178 8.29323 15.0433 8.49056 14.8409L14.09 9.09781Z" fill="white" />
      <path id="Path_7" d="M15.5573 11.7739L14.9644 11.1816L10.0735 16.0782L10.6665 16.6705L15.5573 11.7739Z" fill="white" />
      <path id="Path_7" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M14.9572 11.3671L10.6101 15.8089V16.2027H10.9955L15.3426 11.7608L14.9572 11.3671ZM14.3011 9.84358C14.6635 9.47336 15.2509 9.47337 15.6132 9.84358L16.8336 11.0906C17.1959 11.4608 17.1959 12.061 16.8336 12.4312L11.8961 17.4764C11.7221 17.6542 11.4861 17.754 11.24 17.754H10.0197C9.50732 17.754 9.09192 17.3296 9.09192 16.806V15.5591C9.09192 15.3077 9.18966 15.0665 9.36369 14.8887L14.3011 9.84358Z" />
      <path id="Path_7" fill={fill} fillRule="evenodd" clipRule="evenodd" d="M15.9628 7.97824C16.2596 7.6749 16.7409 7.6749 17.0377 7.97824L18.727 9.70442C19.0239 10.0078 19.0239 10.4996 18.727 10.803C18.4301 11.1063 17.9489 11.1063 17.652 10.803L15.9628 9.07677C15.6659 8.77343 15.6659 8.28158 15.9628 7.97824Z" />
    </g>
    <defs>
      <clipPath id="clip0_7107_2510">
        <rect width="18" height="14.7541" fill="white" transform="translate(1 3)" />
      </clipPath>
    </defs>
  </svg>
);

DataSettingHighlightedIcon.defaultProps = {
  fill: 'var(--border)',
  fill1: 'var(--white)',
  width: 20,
  height: 20,
};

export default DataSettingHighlightedIcon;
