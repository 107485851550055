import { APIRequestStatus } from 'core/constants/redux';
import { ReportBuilderTab } from 'core/models/report-builder/report-builder';
import { defaultPageValue } from 'core/constants/pagination';
import { IReportBuilderState, ReportBuilderActions, ReportBuilderSaveStatus } from './report-builder-store.state';

const initialReportBuilderState: IReportBuilderState = {
  requestProcessing: {
    [ReportBuilderActions.GET_TEMPLATE_LIST]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.INITIAL_SAVE_TEMPLATE]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.SAVE_REPORT_CONFIG]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_ADDITIONAL_FILTERS]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_ADDITIONAL_USER_FIELD_FILTERS]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_ADDITIONAL_DIMENSIONS]:
      APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_ADDITIONAL_MEASURES]:
      APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_FIELDS]:
      APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_PREVIEW_DATA]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.FILTER_DATA_LOAD_REQUEST]: {},
    [ReportBuilderActions.GET_REPORT_RAW_CONFIG_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_REPORT_RENDER_CONFIG_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_MEASURE_DRILLDOWN_CONFIG]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_DRILLDOWN_CONFIG]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.SAVE_DRILLDOWN_CONFIG]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_DEFAULT_DRILLDOWN_COLUMN]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_ADDITIONAL_DRILLDOWN_COLUMN]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_RAW_TEMPLATE_DETAILS]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_ADDITIONAL_USER_DIMENSIONS]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.GET_DATA_SOURCES_REQUEST]: APIRequestStatus.AboutToLoad,
    [ReportBuilderActions.SAVE_DATA_SOURCE_CHANGES_REQUEST]: APIRequestStatus.AboutToLoad,
  },
  reportBuilderInfo: {
    templateList: [],
    additionalFilters: [],
    additionalUserFieldFilters: [],
    additionalDimensions: [],
    additionalMeasures: [],
    fields: [],
    additionalUserFields: [],
  },
  dataSourceInfo: {
    dataSources: null,
    isDataSourceEditMode: false,
  },
  updatedReportBuilderInfo: {
    selectedTemplate: {
      ReportConfig: null,
      Namespace: null,
      TemplateId: null,
      Type: null,
      FilterMetaInfo: null,
      Settings: null,
      TemplateMeta: null,
    },
    reportBuilderSavePayload: null,
    appliedDimensions: [],
    appliedMeasures: [],
    sortState: [],
    calculatedColumns: null,
    expressionStore: {},
    drilldownConfigInfo: {
      drilldownConfigMapping: {},
      drilldownColumnConfig: {},
      enableDrilldown: true,
      drilldownPagination: defaultPageValue,
    },
  },
  assortedInfo: {
    isFormDisabled: true,
    selectedTabInfo: ReportBuilderTab.Data,
    reportId: '',
    settings: null,
    reportBuilderSaveStatus: ReportBuilderSaveStatus.DRAFT,
  },
  previewData: {
    data: null,
    mappedMetaFields: null,
  },
  filterBuilder: {
    appliedFilters: {},
    filterConfig: [],
    isUsersModified: false,
    filterMetaInfo: [],
  },
  previewBackUpInfo: {
    updatedReportBuilderInfo: null,
    filterBuilder: null,
  },
  reportEditInfo: {
    rawReportConfig: null,
    renderReportConfig: null,
    combineConfigLoaded: false,
    isEditing: false,
    temporaryReportId: '',
  },
};

export default initialReportBuilderState;
