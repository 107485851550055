import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
}

const EqualsOpIcon = ({
  width, height, fill,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 10" fill="none">
    <path d="M16 2.77778H0V0H16V2.77778ZM16 10H0V7.23906H16V10Z" fill={fill} />
  </svg>

);

EqualsOpIcon.defaultProps = {
  width: 20,
  height: 16,
  fill: 'var(--border)',
};

export default EqualsOpIcon;
