import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import { ActionIconsForAccordion } from 'core/constants/common';
import { FieldEntitiesType, PreDefinedFieldEntityType } from 'core/constants/report';
import {
  DrilldownConfigMapping, MeasureIdentifier, MeasureIdentifiers, MeasureListPayload,
} from 'redux-v2/report-builder/report-builder-store.state';
import { IColumn, IField } from 'core/models/report-response';
import { displayName, isValidArrayAndNotEmpty, isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IAccordianListItem, IAccordionList } from 'components/common/SideTray/side-tray.interface';
import { IBuilderMeasure } from 'core/models/report-builder/report-builder';
import useLocalize from 'components/common/Localization/useLocalize.hook';
import {
  FieldEntities, getFieldIdentifier, getTextTooltipContentForMeasure, isDrilldownPossible, isUserAggregation,
} from 'core/utils/report.util';
import { findUnderlyingColumn } from 'core/utils/report-expressions';
import { ISideTrayProps } from './drilldown.interface';

export const sideTrayProps: ISideTrayProps = {
  sideTrayPlacement: 'right',
  sideTrayPosition: 478,
};

// returns Icon for sidetray based on entity type.
// @entity: name of the entity.
export const getIconBasedOnEntityType = (entity: string): ActionIconsForAccordion => {
  switch (entity) {
    case FieldEntitiesType.Custom:
      return ActionIconsForAccordion.CUSTOM;
    case PreDefinedFieldEntityType:
      return ActionIconsForAccordion.PREDEFINED;
    default:
      return null;
  }
};

// returns the boolean if drilldown has any empty accordiaon
// @drilldown: DrilldownConfigMapping for measures.
export const isDrilldownEmptyAccord = (drilldown: DrilldownConfigMapping): boolean => {
  const keys = isValidObjectAndNotEmpty(drilldown) ? Object.keys(drilldown) : [];
  return keys?.some((key: string) => drilldown[key]?.length === 0);
};

// returns the entity name.
// @col: measure payload.
export const getEntity = (col: IColumn): FieldEntitiesType | typeof PreDefinedFieldEntityType => {
  if (col?.Props?.Measure?.IsPreDefined) {
    return PreDefinedFieldEntityType;
  } if (col?.BuilderConfig?.Entity === FieldEntitiesType.Custom) {
    return FieldEntitiesType.Custom;
  }
  return col?.Props?.Measure?.Entity as FieldEntitiesType;
};

// remove the empty measures and return the updated measure list.
// @measure: measures list.
export const removeEmptyMeasures = (measure: MeasureListPayload) => Object.fromEntries(
  Object.entries(measure)?.filter(([key, value]) => value.length > 0),
);

// returns the measure info based on name
// @name: measures name.
// @appliedMeasures: all the measures which are applied.
export const getData = (name: string, appliedMeasures: IColumn[]): IColumn => appliedMeasures?.find((measure: IColumn) => measure?.Name === name);

// returns the boolean if it is cross entity
// @selectedMeasureEntity: current selected entity.
// @col: Column info
// @drilldownConfigMapping: mapping for measures
// @activeId: current key for active accorion
const getCrossEntity = (selectedMeasureEntity: Omit<FieldEntitiesType, FieldEntitiesType.Custom>, colEntity: Omit<FieldEntitiesType, FieldEntitiesType.Custom>, selectedMeasureJoinID: string,
  colMeasureJoinID: string): boolean => (selectedMeasureEntity !== colEntity || selectedMeasureJoinID !== colMeasureJoinID);

// returns the tooltip content based on the custom expression or cross entity.
// @isCrossEntity: boolean for cross entity.
// @isCalculatedColumn: boolean for custom expression.
// @measure: measure details.
const getTooltipContent = (isCrossEntity: boolean, isCalculatedColumn: boolean, isUserGrouping: boolean): string => {
  if (isCalculatedColumn) {
    return `${useLocalize('reportBuilder.calculatedValuesTooltip')}`;
  }
  if (isUserGrouping) {
    return `${useLocalize('reportBuilder.userGroupingDrilldownTooltip')}`;
  }
  if (isCrossEntity) {
    return `${useLocalize('reportBuilder.crossEntityValueAlert')}`;
  }
  return null;
};

// returns the grouping for sidetray with respective measure grouping
// @appliedMeasures: All applied measures from data tab.
// @activeId: Current active accoridan key.
// @calculatedColumns: Calculated column list.

export const getGroupedMeasures = (fieldsData: Array<IField>, appliedMeasures: IColumn[], drilldownConfigMapping: DrilldownConfigMapping, activeId: string, calculatedColumns: IBuilderMeasure[], defaultEntity: string, builderMeasuresList:IBuilderMeasure[]): IAccordionList[] => {
  const isCalculated = fromPairs((calculatedColumns || []).map((column) => [column?.Alias, true]));
  const appliedColumnsWithoutDrilldownConfig = appliedMeasures.filter((column: IColumn) => !column.Props.DrilldownEnabled);
  const selectedMeasure: IColumn = appliedMeasures?.find((measure: IColumn) => drilldownConfigMapping?.[activeId]?.[0]?.Name === measure.Name);
  let selectedMeasureEntity = selectedMeasure?.Props?.Measure?.Entity;
  let selectedMeasureJoinID = selectedMeasure?.Props?.Measure?.JoinID;

  if (selectedMeasure?.Props?.Measure?.IsCustomExpression) {
    const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fieldsData, selectedMeasure, defaultEntity);
    selectedMeasureEntity = Entity;
    selectedMeasureJoinID = JoinId;
  }
  const groupedData = groupBy(appliedColumnsWithoutDrilldownConfig, (measures: IColumn) => getEntity(measures));
  const groupedDataKeys = Object.keys(groupedData);

  const groupedMeasures = groupedDataKeys.map((entityName: string) => {
    const filteredMeasuresBasedOnEntity = isValidArrayAndNotEmpty(appliedColumnsWithoutDrilldownConfig) ? appliedColumnsWithoutDrilldownConfig.filter((col: IColumn) => getEntity(col) === entityName) : [];
    const accordianValues = filteredMeasuresBasedOnEntity
      ?.map((measure: IColumn): IAccordianListItem => {
        let colMeasureEntity = measure.Props?.Measure?.Entity;
        let colMeasureJoinID = measure.Props?.Measure?.JoinID;
        if (measure.Props?.Measure?.IsCustomExpression) {
          const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fieldsData, measure, defaultEntity);
          colMeasureEntity = Entity;
          colMeasureJoinID = JoinId;
        }
        const isCrossEntity = drilldownConfigMapping?.[activeId]?.length ? getCrossEntity(selectedMeasureEntity,
          colMeasureEntity, selectedMeasureJoinID, colMeasureJoinID) : false;

        let expr = measure?.Props?.Measure?.Expression;
        if (!expr) {
          const currentMeasure = builderMeasuresList.find((builderMeasure: IBuilderMeasure) => builderMeasure.Alias === measure.Name);
          expr = currentMeasure?.MeasureExpression;
        }
        const isCalculatedMeasure = isCalculated[measure.Name] || !isDrilldownPossible(expr);
        const isUserGrouping = isUserAggregation(expr);
        return {
          name: displayName(measure),
          uniqueKey: getFieldIdentifier(measure),
          toolTipContent: getTextTooltipContentForMeasure(measure),
          data: measure,
          renderIcon: ActionIconsForAccordion.ADD,
          renderDispayIcon: getIconBasedOnEntityType(entityName),
          isItemDisable: isCrossEntity || isCalculatedMeasure || isUserGrouping,
          iconItemToolTipText: getTooltipContent(isCrossEntity, isCalculatedMeasure, isUserGrouping),
        };
      });
    return {
      groupId: entityName,
      headerName: entityName,
      headerIcon: FieldEntities[entityName]?.icon,
      accordValues: accordianValues,
      isCustomExp: false,
      openAccordSectionOnInitialLoad: entityName === PreDefinedFieldEntityType, // to open accord section for pre-defined //dynamic entity based accodian should be open by default
    };
  });

  return groupedMeasures;
};

// returns the formatted column payload with entity and joinId to get default drilldown columns
// @nonExistColumns: list of measure which doesn't have any default columns.
// @appliedMeasures: Current applied measures.
// @drilldownConfigMapping: Mapping for current applied measures in drilldown tab.
// @fields: Field info to get joinId and entity info.
export const getMeasureIdentifiersPayload = (nonExistColumns: string[], appliedMeasures: IColumn[], drilldownConfigMapping: DrilldownConfigMapping, fields: IField[], defaultEntity: string) => nonExistColumns?.reduce((acc: MeasureIdentifiers, id): MeasureIdentifiers => {
  const measureInfo = appliedMeasures?.find((measure: IColumn) => measure?.Id === drilldownConfigMapping?.[id]?.[0]?.Id);
  if (measureInfo?.Props?.Measure?.IsPreDefined) {
    acc[id] = {
      Entity: measureInfo?.Props?.Measure?.Entity as string,
      JoinId: measureInfo?.Props?.Measure?.JoinID || '',
    };
  } else if (measureInfo?.Props?.Measure?.IsCustomExpression) {
    const { Entity, JoinId } = getEntityJoinIdForCustomMeasure(fields, measureInfo, defaultEntity);
    acc[id] = {
      Entity,
      JoinId,
    };
  } else {
    acc[id] = {
      Entity: measureInfo?.Props?.Measure?.Entity as string,
      JoinId: measureInfo?.Props?.Measure?.JoinID || '',
    };
  }
  return acc;
}, {} as MeasureIdentifiers);

export const getEntityJoinIdForCustomMeasure = (fieldsData: Array<IField>, newMeasure: IColumn, fallbackEntity: string) => {
  const data: MeasureIdentifier = {
    Entity: '',
    JoinId: '',
  };
  const column = findUnderlyingColumn(newMeasure?.Props?.Measure?.Expression);
  const fieldInfo = !isValidArrayAndNotEmpty(fieldsData) ? {
    Entity: fallbackEntity || '',
    JoinID: '',
  } : fieldsData?.find((field) => {
    let res = field?.Alias === column?.Column?.SchemaName;
    if (column?.PrependTableName && !column?.TableNameOverride) {
      res = field?.JoinID === column?.TableNameOverride;
    }
    return res;
  });

  data.Entity = fieldInfo?.Entity || fallbackEntity || '';
  data.JoinId = fieldInfo?.JoinID || '';
  return data;
};
